import React from 'react';
import { HalfBond } from './icons/HalfBond';
import { QuarterBond } from './icons/QuarterBond';
import { BoundedBond } from './icons/BoundedBond';
import { QuarterCrossBond } from './icons/QuarterCrossBond';
import { WildBond } from './icons/WildBond';

export const bondOptions = [
  {
    firstLabel: 'Ложковая',
    secondLabel: 'смещение на 1/2',
    value: 'half',
    icon: <HalfBond />,
  },
  {
    firstLabel: 'Ложковая',
    secondLabel: 'смещение на 1/4',
    value: 'fourth',
    icon: <QuarterBond />,
  },
  {
    firstLabel: 'Ложковая',
    secondLabel: 'косое смещение на 1/4',
    value: 'fourth-cross',
    icon: <QuarterCrossBond />,
  },
  {
    firstLabel: 'Цепная',
    secondLabel: '',
    value: 'bonded',
    icon: <BoundedBond />,
  },
  {
    firstLabel: 'Хаотичная',
    secondLabel: '',
    value: 'wild',
    icon: <WildBond />,
  },
];