import React from 'react';

export const ViewLogo = () => (
  <svg width="27" height="20" viewBox="0 0 27 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="14" width="13" height="6" rx="2" fill="#C4C4C4" />
    <rect width="13" height="6" rx="2" fill="#C4C4C4" />
    <rect x="7" y="7" width="13" height="6" rx="2" fill="#C4C4C4" />
    <rect x="14" y="14" width="13" height="6" rx="2" fill="#C4C4C4" />
    <rect x="14" width="13" height="6" rx="2" fill="#C4C4C4" />
    <rect x="21" y="7" width="6" height="6" rx="2" fill="#C4C4C4" />
    <rect y="7" width="6" height="6" rx="2" fill="#C4C4C4" />
  </svg>
);
