export const windowColorOptions = [
  {
    colorCode: '',
    label: 'Без окна',
  },
  {
    colorCode: '#F5FFFA',
    label: 'Белый',
  },
  {
    colorCode: '#D2691E',
    label: 'Светло - коричневый',
  },
  {
    colorCode: '#8B4513',
    label: 'Темно - коричневый',
  },
  {
    colorCode: '#696969',
    label: 'Темно - серый',
  },
];