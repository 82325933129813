import styled, { css } from 'styled-components';

export const ViewerContainer = styled.section`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: 1000px) {
    position: relative;
    background: #2a2b2c;
    ${({ isMobileTutorial }) => isMobileTutorial && css`
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    `}
  } 
`;

export const TutorialContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 27rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  background: url('/images/bg.png');

  @media screen and (max-width: 1000px) {
    margin: auto;
    width: 100%;
    padding-left: 0;
    height: calc(100% + 1px);
    background: none;
  } 

  @media screen {
    overflow: auto;
  }
`;

export const TutorialHeading = styled.h2`
  flex-grow: 0;
  flex-shrink: 0;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  text-align: center;

  color: white;
  margin: 0 6.125rem 2.4375rem 6.125rem;
  padding: 24px 8px 32px 8px;

  background: #434748;
  border-radius: 15px;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    margin: auto;
    width: 100%;
    border-radius: 0px;
    font-size: 32px;
    text-align: center;
  } 
`;

export const TutorialDescription = styled(TutorialHeading)`
  padding: 40px;
  font-size: 20px;

  @media screen and (max-width: 1000px) {
    font-size: 20px;
  } 
`;

export const TutorialActionsList = styled.ul`
  flex-grow: 0;
  flex-shrink: 0;

  margin-left: 6.125rem;
  padding: 0;

  @media screen and (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    width: 18.875rem;
    margin-bottom: 5.125rem;
  }
`;

export const TutorialAction = styled.li`
  list-style-type: none;

  display: flex;
  align-items: center;
  height: 1.5rem;
  
  margin-bottom: .75rem;

  @media screen and (max-width: 1000px) {
    &:nth-of-type(2) {
      margin-bottom: 1.5rem;
    } 

    &:nth-of-type(3) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const TutorialActionIconContainer = styled.div`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TutorialActionIcon = styled.img`
  width: 24px;
`;

export const TutorialDownloadIcon = styled.img`
  margin-right: 16px;
`;

export const TutorialBg = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  pointer-events: none;

  @media screen and (max-width: 1000px) { 
    bottom: 55px;
    width: 100%;
  }
`;

export const TutorialActionText = styled.p`
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #FFFFFF;
`;

export const TutorialClarificationBlock = styled.div`
  flex-grow: 1;

  display: flex;

  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 1000px) {
    margin-bottom: 3.75rem;
  }
`;

export const TutorialClarification = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  margin-bottom: 4.5625rem;
  margin-left: 6.125rem;

  color: #FFFFFF;

  @media screen and (max-width: 1000px) {
    margin: 0 auto 0 auto;
    width: 84.25%;
    text-align: center;
  }
`;

export const ViewerCanvas = styled.canvas`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;
