export const getRandomImage = (images, prevImage) => {
  if (prevImage) {
    const newImages = images.filter((image) => image.src !== prevImage);

    return newImages[Math.floor(Math.random() * newImages.length)];
  }

  return images[Math.floor(Math.random() * images.length)];
};

export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const definePercentageOfBricksInRow = (bricksCountInWidth, data) => {
  const dataAsArray = Object.entries(data).filter((item) => item[0] !== 'null');

  const percentageByKeys = dataAsArray.map((data) => ({ name: data[0], percentage: data[1].percentage }));

  if (percentageByKeys.length === 1) {
    return percentageByKeys.reduce((acc, { name }) => {
      acc = new Array(Math.round(bricksCountInWidth) + 1).fill(name);

      return acc;
    }, []);
  }

  const bricksInOrder = percentageByKeys.reduce((acc, { percentage, name }) => {
    acc = [...acc, ...new Array(Math.round(bricksCountInWidth * (percentage / 100)) + 1).fill(name)];

    return acc;
  }, []);

  return shuffleArray(bricksInOrder);
};
