import React, { useMemo, useContext, useCallback, useState } from 'react';
import Tippy from '@tippyjs/react';
import {
  NavigationContainer, Logo, NavigationButtons, NavigationItem,
  NavigationButton, ButtonLogo, DownloadBlock, DownloadButton,
  DownloadLogoBlock, LogoBlock, NavigationButtonDecoration,
  LogoButton, LinkButton, LinkBlock
} from './styled';
import braerLogo from './braer-logo.svg';
import { AppContext } from 'App';
import { seamColorOptions } from 'components/SeamSelector/options';

import { DownloadLogo } from './logo/DownloadLogo';
import { navigationButtons } from './steps';
import { createImage } from 'createImage';
import { createPDF } from 'createPDF';
import { createBrickLayout } from 'createBrickLayout';
import { defineBricksPercentage } from 'brickSizes';
import { DownloadModal } from './DownloadModal/DownloadModal';
import { FormModal } from './FormModal/FormModal';
import Loader from './Loader';
import { Link } from "react-router-dom";

export const Navigation = () => {
  const {
    setInitialState,
    handleChangeBrickValues,
    setCurrentPage,
    currentPage,
    brickValues,
    brickRanges,
    currentSeamType: seamSize,
    currentSeamColor: seamColor,
    currentBond: bondType,
    currentSize: brickSize,
  } = useContext(AppContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const [loaderText, setLoaderText] = useState('');
  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).imagePath;

  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const handleNavigate = useCallback((e) => {
    const { dataset: { navigationValue } } = e.currentTarget;

    setCurrentPage(Number(navigationValue));
  }, [setCurrentPage]);

  const handleDownloadPDF = () => {
    setIsModalOpen(false);

    if (!isTutorial) {
      (async () => {
        setLoaderText('Генерация PDF');
        setIsLoaderVisible(true);

        await createPDF(valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      })();
    }
  };

  const handleDownloadImage = async () => {
    setIsModalOpen(false);
    setIsFormModalOpen(false);

    if (!isTutorial) {
      await createImage(async (canvas) => {
        setLoaderText('Генерация изображения');
        setIsLoaderVisible(true);

        await createBrickLayout(canvas, valuesForBricks, brickSize, seamSize, seamImage, bondType);

        setIsLoaderVisible(false);
      });
    }
  };

  const handleDownload = () => {
    if (!isTutorial) {
      setIsModalOpen(true);
    }
  };

  const handleOpenForm = () => {
    setIsModalOpen(false);
    setIsFormModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsFormModalOpen(false);
  };

  const handleClickLogo = () => {
    console.log('click logo')
    setInitialState();
    handleChangeBrickValues([
      {
        type: null,
      },
      {
        type: null,
      },
    ]);
  };

  const renderButtons = useMemo(
    () => navigationButtons.map((button, index) => (
      <NavigationItem key={button.label}>
        <Tippy
          placement='right'
          offset={[0, -20]}
          maxWidth={200}
          content={<p>{button.label.toUpperCase()}</p>}
          animation={'fade'}
          duration={0}
        >
          <NavigationButton
            data-navigation-value={button.value}
            active={currentPage === button.value}
            fillIcon={index !== 2}
            onClick={handleNavigate}
          >
            <ButtonLogo>
              {button.logo}
            </ButtonLogo>
            <NavigationButtonDecoration active={currentPage === button.value} />
          </NavigationButton>
        </Tippy>
      </NavigationItem>
    )),
    [currentPage, handleNavigate],
  );

  return (
    <NavigationContainer>
      <DownloadModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleDownloadPDF={handleDownloadPDF}
        handleOpenForm={handleOpenForm}
        setIsFormModalOpen={setIsFormModalOpen}
      />

      <FormModal
        isModalOpen={isFormModalOpen}
        handleCloseModal={handleCloseModal}
        handleDownloadPDF={handleDownloadPDF}
        handleDownloadImage={handleDownloadImage}
      />

      <Loader isVisible={isLoaderVisible} loaderText={loaderText} />

      <LogoBlock>
        <LogoButton onClick={handleClickLogo}><Logo src={braerLogo} /></LogoButton>
      </LogoBlock>

      <NavigationButtons>
        {renderButtons}
      </NavigationButtons>

      <LinkBlock>
        <a style={{ textDecoration: 'none' }} href="https://braer.ru/" target="_blank">
          <LinkButton>braer.ru</LinkButton>
        </a>
      </LinkBlock>

      <DownloadBlock>
        <Tippy
          placement='right'
          offset={[-20, -20]}
          maxWidth={200}
          content={<p>{'СОХРАНИТЬ'}</p>}
          animation={'fade'}
          duration={0}
        >
          <DownloadButton onClick={handleDownload}>
            <DownloadLogoBlock>
              <DownloadLogo />
            </DownloadLogoBlock>
          </DownloadButton>
        </Tippy>
      </DownloadBlock>
    </NavigationContainer>
  );
};
