import React from 'react';

export const SizeIcon1nf = () => (
  <svg width="102" height="37" viewBox="0 0 92 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.8077 11.3846L25.6154 11.3846L25.6154 0L49.8077 2.11496e-06V11.3846Z" fill="black" />
    <path d="M12.8077 12.8078H37V24.1925H12.8077V12.8078Z" fill="black" />
    <path d="M24.1923 25.6155H0V37.0002H24.1923V25.6155Z" fill="black" />
    <path d="M49.8077 25.6155H25.6154V37.0002H49.8077V25.6155Z" fill="black" />
    <path d="M75.4231 25.6155H51.2308V37.0002H75.4231V25.6155Z" fill="black" />
    <path d="M38.4231 24.1923L62.6154 24.1923V12.8077L38.4231 12.8077V24.1923Z" fill="black" />
    <path d="M75.8077 11.3846L51.6154 11.3846V0L75.8077 2.11496e-06V11.3846Z" fill="black" />
    <path d="M38.8077 12.8078H63V24.1925H38.8077V12.8078Z" fill="black" />
    <path d="M50.1923 25.6155H26V37.0002H50.1923V25.6155Z" fill="black" />
    <path d="M75.8077 25.6155H51.6154V37.0002H75.8077V25.6155Z" fill="black" />
    <path d="M101.423 25.6155H77.2308V37.0002H101.423V25.6155Z" fill="black" />
    <path d="M64.423 24.1923L88.6154 24.1923V12.8077L64.423 12.8077V24.1923Z" fill="black" />
  </svg>
);
