import React from 'react';

export const BrickLogo = () => (
  <svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 10.4167C0 9.63426 0.615609 9 1.375 9H14.625C15.3844 9 16 9.63426 16 10.4167V15.5833C16 16.3657 15.3844 17 14.625 17H1.375C0.615608 17 0 16.3657 0 15.5833V10.4167Z" fill="#f4f4f4" />
    <path d="M17 10.4167C17 9.63426 17.6156 9 18.375 9H31.625C32.3844 9 33 9.63426 33 10.4167V15.5833C33 16.3657 32.3844 17 31.625 17H18.375C17.6156 17 17 16.3657 17 15.5833V10.4167Z" fill="#f4f4f4" />
    <path d="M8 1.41667C8 0.634263 8.61561 0 9.375 0L22.6875 0C23.4469 0 24.0625 0.634263 24.0625 1.41667V6.58333C24.0625 7.36574 23.4469 8 22.6875 8H9.375C8.61561 8 8 7.36574 8 6.58333V1.41667Z" fill="#f4f4f4" />
  </svg>

);
