import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

export const SizeSelectorContainer = styled.div`
  margin: .75rem .75rem .8125rem .9375rem;

  height: calc(100% - 1.5675rem);
  width: 100%;
`;

export const SizeOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SizeIsNotAvailable = styled.div`
  position: absolute;
  z-index: 99;
  background: rgba(128, 128, 128, 0.3);
  width: 17.9375rem;
  height: 4.875rem;
  cursor: not-allowed;

  @media screen and (max-width: 1000px) {
    height: 4.9375rem;
    width: calc(100% - 27px);
  }
`;

export const SizeOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.875rem;
  border-radius: 15px;
  margin-bottom: 16px;

  box-sizing: border-box;
  border: 1px solid transparent;

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      border: 1px solid #F48120;
    }
  `)}

  ${ifProp({ isActive: true }, css`
    background: #27282A;
  `)}
`;

export const SizeOptionIconContainer = styled.div`
  flex-basis: 9.9375rem;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg path {
    fill: #f4f4f4;
  }

  ${ifProp({ isActive: true }, css`
    svg path {
      fill: #F48120;
    }
  `)}
`;

export const SizeOptionText = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 8rem;
  flex-grow: 0;
  flex-shrink: 0;
`;

export const SizeOptionFirstLabel = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: white;
  margin: 0;
  
  margin-top: 1.375rem;
`;

export const SizeOptionSecondLabel = styled(SizeOptionFirstLabel)`
  margin-top: 0.4375rem;
`;

export const ChooseSizeButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #F48120;
  border-radius: 0.1875rem;

  cursor: pointer;
  position: absolute;
`;

export const SizeOptionsTitle = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  color: #FFFFFF;

  opacity: 0.5;
  margin: 0;

  margin-left: .8125rem;
  margin-top: .625rem;
  margin-bottom: .5rem;
`;