import React, { useContext } from 'react';
import { SidebarContainer, SidebarWrapper } from './styled';
import Navigation from 'components/Navigation';

import BrickSelector from 'components/BrickSelector';
import SizeSelector from 'components/SizeSelector';
import SeamSelector from 'components/SeamSelector';
import BondSelector from 'components/BondSelector';
import WindowSelector from 'components/WindowSelector';

import { AppContext } from 'App';

export const Sidebar = () => {
  const {
    currentPage = 0,
    currentSize,
    currentBond,
    currentSeamType,
    currentSeamColor,
    currentWindowColor,
    brickValues,
    brickRanges,

    handleChangeBrickValues,
    handleChangeBrickRanges,
    handleChangeSize,
    handleChangeSeamType,
    handleChangeSeamColor,
    handleChangeWindowColor,
    handleChangeBond,
    handleAddBrick,
  } = useContext(AppContext);

  const handleChangeBrickType = (data) => {
    handleChangeBrickValues(data);
  };

  const handleChangeBrickRange = (data) => {
    handleChangeBrickRanges(data);
  };

  const components = [
    <BrickSelector
      brickValues={brickValues}
      brickRanges={brickRanges}
      onChangeBrick={handleChangeBrickType}
      onChangeRange={handleChangeBrickRange}
      onAddBrick={handleAddBrick}
    />,
    <SizeSelector
      currentSize={currentSize}
      brickValues={brickValues}
      onChangeSize={handleChangeSize}
    />,
    <SeamSelector
      currentSeamType={currentSeamType}
      currentSeamColor={currentSeamColor}
      onChangeSeamType={handleChangeSeamType}
      onChangeSeamColor={handleChangeSeamColor}
    />,
    <BondSelector
      currentBond={currentBond}
      onChangeBond={handleChangeBond}
    />,
    <WindowSelector
      currentWindowColor={currentWindowColor}
      onChangeWindowColor={handleChangeWindowColor}
    />,
  ];

  return (
    <SidebarWrapper>
      <Navigation />
      <SidebarContainer>{components[currentPage]}</SidebarContainer>
    </SidebarWrapper>
  );
};
