import React from 'react';

export const SizeIcon14nf = () => (
  <svg width="105" height="39" viewBox="0 0 105 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M51.2676 0H17.7465V15.7746H51.2676V0Z" fill="black" />
    <path d="M0 17.7465H33.5211V33.5211H0V17.7465Z" fill="black" />
    <path d="M35.493 17.7465H69.0141V33.5211H35.493V17.7465Z" fill="black" />
    <path d="M70.9859 17.7465H104.507V33.5211H70.9859V17.7465Z" fill="black" />
    <path d="M53.2394 15.7749L86.7605 15.7749V0.00023365L53.2394 0.00023072V15.7749Z" fill="black" />
  </svg>
);
