import React, { useMemo } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { TableHead, TableBody, TableHeadRow, TableHeadCell, TableBodyCell, TableBodyRow, Image } from './styled';

export const Table = ({ data = [] }) => {
  const imageColumn = [
    {
      Cell: ({ cell: { value: image } }) => (
        <>
          <Image brickImage={image} />
        </>
      ),
      accessor: 'image',

      width: 130,
    },
    {
      Header: () => <p>Название кирпичей</p>,
      accessor: 'name',
      width: 280,
    },
  ];

  const columns = useMemo(
    () => [
      ...imageColumn,
      {
        Header: '%',
        accessor: 'percent',
        width: 68,
      }
    ],
    // eslint-disable-next-line
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useBlockLayout);

  return (
    <div {...getTableProps()} className="table">
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableHeadRow {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <TableHeadCell {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </TableHeadCell>
            ))}
          </TableHeadRow>
        ))}
      </TableHead>

      <TableBody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <TableBodyRow {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <TableBodyCell {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          }
        )}
      </TableBody>
    </div>
  );
};
