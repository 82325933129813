import { bricksSizesByFormat, getBondedPredefinedWidths, getWildPredefinedWidths } from 'brickSizes';
import { definePercentageOfBricksInRow, getRandomImage } from 'brickLayoutHelpers';

const drawBrick = async (context, width, height, shadow, x, y, image, mark, seamSizeNumber, seamImage, pokeShadow) => {
  await new Promise((resolve) => {
    for (let seam = 0; seam < Math.ceil(width / 22) + 1; seam += 1) {
      if (y !== 0) {
        context.drawImage(
          seamImage,
          x + (seam * 22),
          y - seamSizeNumber,
          22,
          seamSizeNumber,
        );
      }
    }

    for (let verticalSeam = 0; verticalSeam < height; verticalSeam += height) {
      if (x !== 0) {
        context.drawImage(
          seamImage,
          x - seamSizeNumber,
          y,
          seamSizeNumber,
          height,
        );
      }
    }

    resolve();
  });

  context.drawImage(
    image,
    x,
    y,
    width,
    height,
  );

  if (mark) {
    context.fillStyle = 'white';
    context.fillRect(x + 10, height / 8, 16, 16);

    context.font = '14px';
    context.fillStyle = 'black';
    context.fillText(mark, x + 15, height === 22 ? height - 6 : height - 12);
  }

  await new Promise((resolve) => {
    if (width < 84) {
      context.drawImage(
        pokeShadow,
        x,
        y,
        width + 3,
        height === 22 ? height + 2.5 : height + 3,
      );
    } else {
      context.drawImage(
        shadow,
        x,
        y,
        width + 3,
        height + 3,
      );
    }

    resolve();
  });
};

const renderHalfBond = async (
  {
    context, bricksCountInHeight, bricksCountInWidth,
    bricksImages, seamSizeNumber, seamImage, brickHeight, brickWidth,
    shadow, pokeShadow,
  },
  marksArray,
) => {
  let prevImage = null;

  for (let brickY = 0; brickY <= bricksCountInHeight * brickHeight; brickY += brickHeight + seamSizeNumber) {
    const keysInPercentageOrder = definePercentageOfBricksInRow(bricksCountInWidth + 5, bricksImages);
    const yIndex = brickY / (brickHeight + seamSizeNumber);

    const isRenderMarks = yIndex === 0 && marksArray && marksArray.length > 0;

    for (let brickX = 0; brickX <= (bricksCountInWidth + 5) * brickWidth; brickX += brickWidth + seamSizeNumber) {
      const brickXPosition = (() => {
        if ((brickY / (brickHeight + seamSizeNumber)) % 2 === 1) {
          return brickX - (brickWidth / 2);
        } else {
          return brickX;
        }
      })();

      const index = brickX / (brickWidth + seamSizeNumber);
      const brickTypeForIndex = keysInPercentageOrder[index];

      const newImage = getRandomImage(bricksImages[brickTypeForIndex].images, prevImage);

      drawBrick(
        context, brickWidth, brickHeight, shadow,
        brickXPosition,
        brickY,
        newImage,
        isRenderMarks && marksArray.indexOf(brickTypeForIndex) + 1,

        seamSizeNumber,
        seamImage,
        pokeShadow,
      );

      prevImage = newImage.src;
    }
  }
};

const renderFourthBond = async (
  {
    context, bricksCountInHeight, bricksCountInWidth,
    seamSizeNumber, bricksImages, seamImage, brickWidth, brickHeight,
    shadow, pokeShadow,
  },
  marksArray,
) => {
  let prevImage = null;

  for (let brickY = 0; brickY <= bricksCountInHeight * brickHeight; brickY += brickHeight + seamSizeNumber) {
    const keysInPercentageOrder = definePercentageOfBricksInRow(bricksCountInWidth + 5, bricksImages);

    const yIndex = brickY === 0 / (brickHeight + seamSizeNumber);

    const isRenderMarks = yIndex && marksArray && marksArray.length > 0;

    for (let brickX = 0; brickX <= (bricksCountInWidth + 5) * brickWidth; brickX += brickWidth + seamSizeNumber) {
      const brickXPosition = (() => {
        if ((brickY / (brickHeight + seamSizeNumber)) % 2 === 1) {
          return brickX - (brickWidth / 4);
        } else {
          return brickX;
        }
      })();

      const index = brickX / (brickWidth + seamSizeNumber);
      const brickTypeForIndex = keysInPercentageOrder[index];

      const newImage = getRandomImage(bricksImages[brickTypeForIndex].images, prevImage);

      drawBrick(
        context, brickWidth, brickHeight, shadow,
        brickXPosition,
        brickY,
        newImage,
        isRenderMarks && marksArray.indexOf(brickTypeForIndex) + 1,

        seamSizeNumber,
        seamImage,
        pokeShadow,
      );

      prevImage = newImage.src;
    }
  }
};

const renderFourthCrossBond = async (
  {
    context, bricksCountInHeight, bricksCountInWidth,
    seamSizeNumber, bricksImages, seamImage, brickWidth, brickHeight,
    shadow, pokeShadow,
  },
  marksArray,
) => {
  let prevImage = null;

  for (let brickY = 0; brickY <= bricksCountInHeight * brickHeight; brickY += brickHeight + seamSizeNumber) {
    const keysInPercentageOrder = definePercentageOfBricksInRow(bricksCountInWidth + 5, bricksImages);

    const yIndex = brickY === 0 / (brickHeight + seamSizeNumber);

    const isRenderMarks = yIndex && marksArray && marksArray.length > 0;

    for (let brickX = 0; brickX <= (bricksCountInWidth + 5) * brickWidth; brickX += brickWidth + seamSizeNumber) {
      const brickXPosition = (() => {
        const index = brickY / (brickHeight + seamSizeNumber);

        return brickX - (brickWidth / 4 * (index <= 4 ? index : index % 4));
      })();

      const index = brickX / (brickWidth + seamSizeNumber);
      const brickTypeForIndex = keysInPercentageOrder[index];

      const newImage = getRandomImage(bricksImages[brickTypeForIndex].images, prevImage);

      drawBrick(
        context, brickWidth, brickHeight, shadow,
        brickXPosition,
        brickY,
        newImage,
        isRenderMarks && marksArray.indexOf(brickTypeForIndex) + 1,

        seamSizeNumber,
        seamImage,
        pokeShadow,
      );

      prevImage = newImage.src;
    }
  }
};

const renderBondedBond = async (
  {
    context, bricksCountInHeight, bricksCountInWidth,
    seamSizeNumber, bricksImages, brickWidth, seamImage, brickHeight, brickPokeWidth,
    shadow, pokeShadow,
  },
  marksArray,
) => {
  let prevImage = null

  const predefinedWidths = getBondedPredefinedWidths(brickWidth, brickPokeWidth);

  for (let brickY = 0; brickY <= bricksCountInHeight * brickHeight; brickY += brickHeight + seamSizeNumber) {
    const keysInPercentageOrder = definePercentageOfBricksInRow(bricksCountInWidth + 20, bricksImages);

    const yIndex = brickY / (brickHeight + seamSizeNumber);

    const isRenderMarks = yIndex === 0 && marksArray && marksArray.length > 0;

    let prevPosition;

    for (let brickX = 0; brickX <= bricksCountInWidth + 20; brickX += 1) {
      const brickWidth = predefinedWidths[yIndex % predefinedWidths.length][brickX % predefinedWidths[0].length];

      // eslint-disable-next-line
      const brickXPosition = (() => {
        if (prevPosition !== undefined) {
          const oldPosition = prevPosition;

          prevPosition = oldPosition + brickWidth + seamSizeNumber;

          return oldPosition;
        } else {
          if (yIndex % 2 === 1 && yIndex !== 0) {
            prevPosition = -(brickWidth / 2) + brickWidth + seamSizeNumber;

            return -(brickWidth / 2);
          } else {
            prevPosition = 0 + brickWidth + seamSizeNumber;

            return 0;
          }
        }
      })();

      const brickTypeForIndex = keysInPercentageOrder[brickX];

      const newImage = getRandomImage(bricksImages[brickTypeForIndex].images, prevImage)

      drawBrick(
        context, brickWidth, brickHeight, shadow,
        brickXPosition,
        brickY,
        newImage,
        isRenderMarks && marksArray.indexOf(brickTypeForIndex) + 1,

        seamSizeNumber,
        seamImage,
        pokeShadow,
      );

      prevImage = newImage.src;
    }
  }
};

const renderWildBond = async (
  {
    context, bricksCountInHeight, bricksCountInWidth,
    seamSizeNumber, bricksImages, seamImage, brickWidth, brickHeight, brickPokeWidth,
    shadow, pokeShadow,
  },
  marksArray,
) => {
  let prevImage = null;

  const marksDataArray = [];

  const predefinedWidths = getWildPredefinedWidths(brickWidth, brickPokeWidth);

  for (let brickY = 0; brickY <= bricksCountInHeight * brickHeight; brickY += brickHeight + seamSizeNumber) {
    const keysInPercentageOrder = definePercentageOfBricksInRow(bricksCountInWidth + 20, bricksImages);

    const yIndex = brickY / (brickHeight + seamSizeNumber);

    const isRenderMarks = yIndex === 0 && marksArray && marksArray.length > 0;

    let prevPosition;

    for (let brickX = 0; brickX <= bricksCountInWidth + 20; brickX += 1) {
      const brickWidth = predefinedWidths[yIndex % predefinedWidths.length][brickX % predefinedWidths[0].length];

      // eslint-disable-next-line
      const brickXPosition = (() => {
        if (prevPosition !== undefined) {
          const oldPosition = prevPosition;

          prevPosition = oldPosition + brickWidth + seamSizeNumber;

          return oldPosition;
        } else {
          if (yIndex % 2 === 1 && yIndex !== 0) {
            prevPosition = -(brickWidth / 2) + brickWidth + seamSizeNumber;

            return -(brickWidth / 2);
          } else {
            prevPosition = 0 + brickWidth + seamSizeNumber;

            return 0;
          }
        }
      })();

      const brickTypeForIndex = keysInPercentageOrder[brickX];

      const newImage = getRandomImage(bricksImages[brickTypeForIndex].images, prevImage)

      const marksData = drawBrick(
        context, brickWidth, brickHeight, shadow,
        brickXPosition,
        brickY,
        newImage,
        isRenderMarks && marksArray.indexOf(brickTypeForIndex) + 1,

        seamSizeNumber,
        seamImage,
        pokeShadow,
      );

      if (marksData) {
        marksDataArray.push(marksData);
      }

      prevImage = newImage.src;
    }
  }

  if (marksDataArray && marksDataArray.length > 0) {
    return marksDataArray;
  };
};

const renderBricksInCanvas = async (
  data,
  bondType,
  marksArray,
) => {
  switch (bondType) {
    case 'half':
      await renderHalfBond(data, marksArray);
      break;

    case 'fourth':
      await renderFourthBond(data, marksArray);
      break;

    case 'fourth-cross':
      await renderFourthCrossBond(data, marksArray);
      break;

    case 'bonded':
      await renderBondedBond(data, marksArray);
      break;

    case 'wild':
      await renderWildBond(data, marksArray);
      break;

    default:
      break;
  }
};

export const createBrickLayout = async (canvasEl, brickData, brickSize, seamSize, seamImagePath, bondType, isWithMarks, mode3d) => {
  const context = canvasEl.getContext('2d');

  const canvasWidth = mode3d ? 4000 : canvasEl.offsetWidth;
  const canvasHeight = mode3d ? 2160 : canvasEl.offsetHeight;

  const seamSizeNumber = seamSize === 'min' ? 2 : seamSize === 'max' ? 4 : 3;

  const { height: brickHeight, width: brickWidth } = bricksSizesByFormat[brickSize];

  const brickPokeWidth = brickSize === '0.7' || brickSize === '0.9'
    ? brickWidth * 0.34
    : brickWidth * 0.47;

  const brickWidthForBond = bondType === 'bonded'
    ? brickPokeWidth
    : brickWidth;

  const bricksCountInWidth = Math.round(canvasWidth / brickWidthForBond) + 1;
  const bricksCountInHeight = Math.round(canvasHeight / brickHeight);

  const bricksImages = {};

  const marksArray = brickData.map(({ type }) => type).filter((mark) => Boolean(mark));

  for (const brick of brickData) {
    const brickTypeImages = (await Promise.allSettled(
      brick.imagesPaths.map((path) => new Promise((resolve) => {
        const img = new Image();

        img.src = path;

        img.onload = () => {
          resolve(img);
        };
      }))
    ))
      .filter(({ status }) => status === 'fulfilled')
      .map(({ value }) => value);

    if (bricksImages[brick.type]) {
      bricksImages[brick.type].percentage += brick.percent;
    } else {
      bricksImages[brick.type] = {
        images: brickTypeImages,
        percentage: brick.percent,
      };
    }
  }

  const seamImage = await (new Promise((resolve) => {
    const img = new Image();

    img.src = seamImagePath;

    img.onload = () => {
      resolve(img);
    };
  }));

  const shadow = await (new Promise((resolve) => {
    const img = new Image();

    img.src = brickSize === '1.4' || brickSize === '0.9'
      ? `${process.env.PUBLIC_URL}/images/shadow14nf.png`
      : `${process.env.PUBLIC_URL}/images/shadow1nf.png`;

    img.onload = () => {
      resolve(img);
    };
  }));

  const pokeShadow = await (new Promise((resolve) => {
    const img = new Image();

    if (brickSize === '1.4') {
      img.src = `${process.env.PUBLIC_URL}/images/shadow14nf-tychok.png`;
    }

    if (brickSize === '1') {
      img.src = `${process.env.PUBLIC_URL}/images/shadow1nf-tychok.png`;
    }

    img.src = `${process.env.PUBLIC_URL}/images/shadow07nf-tychok.png`;

    img.onload = () => {
      resolve(img);
    };
  }));

  context.clearRect(0, 0, canvasEl.width, canvasEl.height);

  mode3d && context.drawImage(seamImage, 0, 0, canvasWidth, canvasHeight);

  await renderBricksInCanvas(
    {
      context,
      bricksCountInHeight,
      bricksCountInWidth,
      canvasHeight,
      canvasWidth,
      seamSizeNumber,
      bricksImages,

      brickHeight,
      brickWidth,
      brickPokeWidth,

      seamImage,
      shadow,
      pokeShadow,
      mode3d,
    },

    bondType,
    isWithMarks ? marksArray : null,
  );
};
