import React from 'react';

export const BondLogo = () => (
  <svg width="33" height="17" viewBox="0 0 33 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 1.41667C0 0.634253 0.615609 0 1.375 0H14.625C15.3844 0 16 0.634253 16 1.41667V6.58333C16 7.36575 15.3844 8 14.625 8H1.375C0.615609 8 0 7.36575 0 6.58333V1.41667Z" fill="#F4F4F4" />
    <path d="M0 10.4167C0 9.63425 0.615609 9 1.375 9H6.625C7.38439 9 8 9.63425 8 10.4167V15.5833C8 16.3657 7.38439 17 6.625 17H1.375C0.615609 17 0 16.3657 0 15.5833V10.4167Z" fill="#F4F4F4" />
    <path d="M25 10.4167C25 9.63425 25.6156 9 26.375 9H31.625C32.3844 9 33 9.63425 33 10.4167V15.5833C33 16.3657 32.3844 17 31.625 17H26.375C25.6156 17 25 16.3657 25 15.5833V10.4167Z" fill="#F4F4F4" />
    <path d="M10.375 9C9.61561 9 9 9.63425 9 10.4167V15.5833C9 16.3657 9.61561 17 10.375 17H22.625C23.3844 17 24 16.3657 24 15.5833V10.4167C24 9.63425 23.3844 9 22.625 9H10.375Z" fill="#F4F4F4" />
    <path d="M18.375 0C17.6156 0 17 0.634253 17 1.41667V6.58333C17 7.36575 17.6156 8 18.375 8H31.625C32.3844 8 33 7.36575 33 6.58333V1.41667C33 0.634253 32.3844 0 31.625 0H18.375Z" fill="#F4F4F4" />
  </svg>
);
