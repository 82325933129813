import React, { useState, useCallback } from 'react';
import {
  SeamColorSelectorContainer, SeamSelectors, SeamSelectorHeaderText, SeamColorOptions,
  SeamColor, SeamColorText, SeamColorOption, SeamContentWrapper,
} from './styled';
import { windowColorOptions } from './options';
import CustomScrollbar from 'components/CustomScrollbar';

export const WindowSelector = ({ currentWindowColor, onChangeWindowColor }) => {
  const handleClickOnSeamColorOptions = useCallback((windowColor) => () => {
    onChangeWindowColor(windowColor);
  }, [onChangeWindowColor]);

  const renderSeamColorOptions = windowColorOptions.map(({ colorCode, label }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = colorCode === currentWindowColor;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <SeamColorOption
        key={colorCode}
        isActive={isActiveOption}
        onClick={handleClickOnSeamColorOptions(colorCode)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <SeamColor color={colorCode} isActive={isActiveOption} />

        <SeamColorText isActive={isActiveOption}>{label}</SeamColorText>
      </SeamColorOption>
    );
  });

  return (
    <SeamSelectors>
      <SeamSelectorHeaderText>
        Цвет окна
      </SeamSelectorHeaderText>
      <SeamContentWrapper>
        <CustomScrollbar leftPosition='18.5rem'>
          <SeamColorSelectorContainer>
            <SeamColorOptions>
              {renderSeamColorOptions}
            </SeamColorOptions>
          </SeamColorSelectorContainer>
        </CustomScrollbar>
      </SeamContentWrapper>
    </SeamSelectors>
  );
};
