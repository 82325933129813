import styled, { css } from 'styled-components';

const size = {
  mobile: '325px',
  tablet: '768px',
  laptop: '1000px',
  desktop: '1500px',
  desktopL: '1800px',
};

const device = {
  mobile: `(min-width: ${size.mobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
  desktopL: `(min-width: ${size.desktopL})`,
};

export const ViewerContainer = styled.section`
  position: fixed !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  flex-grow: 1;
  overflow: hidden;

  @media screen and (max-width: 1000px) {
    margin: 3.4375rem 0;
    position: relative;
    ${({ isMobileTutorial }) => isMobileTutorial && css`
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
    `}
  } 
`;

export const TutorialContainer = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 27rem;

  box-sizing: border-box;
  background: url('/images/bg.png');

  @media screen and (max-width: 1000px) {
    margin: 0;
    width: 100%;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
    padding-left: 0;
    padding-top: 48px;
    padding-bottom: 48px;
    height: calc(100% - 7rem);
    background: none;
  } 

  @media screen {
    overflow: auto;
  }
`;

export const TutorialHeading = styled.h2`
  display: flex;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  text-align: center;
  max-width: 1000px;
  margin: 20px 40px;
  padding: 40px;

  color: white;

  background: #434748;
  border-radius: 15px;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    margin: 0 auto;
    width: 100%;
    border-radius: 0px;
    font-size: 32px;
    text-align: center;
  } 
`;

export const TutorialDescription = styled(TutorialHeading)`
  margin: 40px auto 0 40px;
  padding: 40px;
  padding-top: 3rem;
  max-width: 1000px;
  font-size: 20px;
  margin-top: 630px;

  @media screen and (max-width: 1000px) {
    font-size: 20px;
    margin-left: 0;
  } 
`;

export const TutorialActionsList = styled.ul`
  flex-grow: 0;
  flex-shrink: 0;

  margin-left: 6.125rem;
  padding: 0;

  @media screen and (max-width: 1000px) {
    margin-left: auto;
    margin-right: auto;
    width: 18.875rem;
    margin-bottom: 5.125rem;
  }
`;

export const TutorialAction = styled.li`
  list-style-type: none;

  display: flex;
  align-items: center;
  height: 1.5rem;
  
  margin-bottom: .75rem;

  @media screen and (max-width: 1000px) {
    &:nth-of-type(2) {
      margin-bottom: 1.5rem;
    } 

    &:nth-of-type(3) {
      margin-bottom: 1.5rem;
    }
  }
`;

export const TutorialActionIconContainer = styled.div`
  margin-right: 1rem;
  height: 1.5rem;
  width: 1.5rem;
  min-width: 1.5rem;
  border-radius: 3px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TutorialActionIcon = styled.img`
  width: 24px;
`;

export const TutorialDownloadIcon = styled.img`
  margin-right: 16px;
`;

export const TutorialBg = styled.img`
  position: absolute;
  bottom: 0px;
  right: 0px;
  pointer-events: none;

  @media screen and (max-width: 1000px) { 
    bottom: 55px;
    width: 100%;
  }
`;

export const TutorialActionText = styled.p`
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.1875rem;

  color: #FFFFFF;
`;

export const TutorialClarificationBlock = styled.div`
  flex-grow: 1;

  display: flex;

  flex-direction: column;
  justify-content: flex-end;

  @media screen and (max-width: 1000px) {
    margin-bottom: 3.75rem;
  }
`;

export const TutorialClarification = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;

  margin-bottom: 4.5625rem;
  margin-left: 6.125rem;

  color: #FFFFFF;

  @media screen and (max-width: 1000px) {
    margin: 0 auto 0 auto;
    width: 84.25%;
    text-align: center;
  }
`;

export const ViewerCanvas = styled.canvas`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`;

export const ImagesBlock = styled.div`
  background: #434748;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 15px;
  margin: 40px;
  padding: 40px;
  max-width: 1000px;
  box-sizing: border-box;

  @media screen and (max-width: 1000px) {
    margin: 40px auto;
  }
`;

export const ImageBlock = styled.div`
  display: flex;
  border: 3px solid #E7863C;
  border-radius: 15px;
  max-width: 488px;
  min-width: 350px;
  height: 295px;
  padding: 16px;
  margin-right: 18px;
  margin-bottom: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 45%;
  box-sizing: border-box;
  @media screen and (max-width: 1000px) {
    height: 345px;
    flex-basis: 100%;
    margin-right: 0;
  }
`;

export const ImageText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 24px;

  color: #E5E5E5;
`;

export const Window = styled.div`
  position: fixed;
  right: -240px;
  top: 187px;
  width: 480px;
  height: 480px;
  z-index: 1;

  ${({ mode }) => mode === '3d' && css`
    display: none;
  `}

  @media screen and (max-width: 1000px) {
    width: 400px;
    height: 200px;
    right: -200px;
    top: -30px;
  }
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Developer = styled.div`
  z-index: 1;
  position: absolute;
  bottom: 10px;
  right: 18px;
  font-size: 13px;
  color: white;

  & > a {
    color: currentColor;
  }

  @media screen and (max-width: 1000px) {
    bottom: 60px;
  }
`;


// 3D START
export const Viewer3dRoot = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - 19.625rem - 5.3125rem);
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  ${({ hidden }) => hidden && css`
    visibility: hidden;
  `}
`;

export const ArrowLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #C85F37;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  left: calc(19.625rem + 5.3125rem + 40px);
  bottom: 24px;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,.1);

  &:after {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-top: 3px solid #C85F37;
    border-left: 3px solid #C85F37;
    border-radius: 3px;
    transform: rotate(-45deg);
    margin-left: 6px;
  }
  
  @media screen and (max-width: 1000px) {
    bottom: 7.4375rem;
    left: 40px;
  }
  @media ${device.laptop} {
    top: 50%;
  }
`

export const ArrowRight = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  color: #C85F37;
  cursor: pointer;
  position: absolute;
  z-index: 3;
  right: 40px;
  bottom: 24px;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,.1);

  &:after {
    display: block;
    content: '';
    width: 12px;
    height: 12px;
    border-top: 3px solid #C85F37;
    border-right: 3px solid #C85F37;
    border-radius: 3px;
    transform: rotate(45deg);
    margin-right: 6px;
  }
  @media screen and (max-width: 1000px) {
    bottom: 7.4375rem;
  }
  @media ${device.laptop} {
    top: 50%;
  }
`

export const ModeWrapper = styled.div`
  display: flex;
  width: 106px;
  height: 40px;
  color: #C85F37;
  cursor: pointer;
  position: absolute;
  background-color: white;
  border-radius: 4px;
  font-size: 24px;
  z-index: 3;
  right: 80px;
  top: 5%;
  box-shadow: 0px 5px 15px 2px rgba(0,0,0,.1);
`;

export const ModeButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  padding: 4px 12px;
  height: 40px;
  border-radius: 4px;
  color: #C85F37;
  cursor: pointer;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;

  ${({ active }) => active && css`
    color: white;
    background-color: #C85F37;
  `}
`;

export const House = styled.div`
  position: fixed;
  top: 30px;
  box-sizing: border-box;
  padding: 120px 0;
  width: calc(100% - 399px);
  height: calc(100% - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  visibility: hidden;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  ${({ scale }) => scale && css`
  transform: scale(${scale});
  `}

  ${({ visible }) => visible && css`
    visibility: visible;
  `}
`;

export const House1Image = styled.div`
  position: absolute;
  z-index: 99;
  width: 100%;

  @media ${device.mobile} {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 425px;
  }

  @media ${device.tablet} {
    width: 756px;
  }

  @media ${device.laptop} {
    width: 756px;
  }

  @media ${device.desktop} {
    width: 1100px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
  }
  
  & > img {
    width: 100%;
  }
`;

export const CubesWrapper1 = styled.div`
  position: relative;
  z-index: 1;
  transform: translateZ(-1px);

  width: 425px;
  min-width: 425px;
  height: 283px;

  @media ${device.mobile} {
    width: 425px;
    min-width: 425px;
    height: 283px;
  }

  @media ${device.tablet} {
    width: 756px;
    min-width: 756px;
    height: 504px;
  }

  @media ${device.laptop} {
    width: 756px;
    height: 504px;
  }

  @media ${device.desktop} {
    width: 1100px;
    height: 733px;
  }
  
  @media ${device.desktopL} {
    width: 1400px;
    height: 933px;
  }
`

export const Cube11Wrapper = styled.div`
  z-index: 10;
  position: absolute;
  width: 1400px;
  height: 560px;

  & > div > img {
    image-rendering: -webkit-optimize-contrast;
  }
`

export const Cube11 = styled.div`
  position: absolute;
  z-index: 10;
  transform-style: preserve-3d;
  transform-origin: 100% 74%;
  perspective-origin: 100% 100%;

  top: 14.1%;
  left: -13.35%;
  width: 25%;
  height: 25%;
  transform: perspective(350px) rotateX(0) rotateY(-57.8deg) rotateZ(0deg);
  transform-origin: 100% 80%;

  @media ${device.mobile} {
    top: 10.4%;
    left: -22.45%;
    width: 33%;
    height: 33%;
    transform: perspective(487px) rotateX(0) rotateY(-57.5deg) rotateZ(0deg);
    transform-origin: 100% 85%;
  }

  @media ${device.tablet} {
    top: 18.1%;
    left: -44.6%;
    width: 63%;
    height: 63%;
    transform: perspective(875px) rotateX(0) rotateY(-57.7deg) rotateZ(0deg);
    transform-origin: 100% 80%;
  }

  @media ${device.laptop} {
    top: 19%;
    left: -41.2%;
    width: 60%;
    height: 60%;
    transform: perspective(840px) rotateX(0) rotateY(-57.8deg) rotateZ(0deg);
    transform-origin: 100% 81%;
  }

  @media ${device.desktop} {
    top: 27.7%;
    left: -72.7%;
    width: 100%;
    height: 100%;
    transform: perspective(1233px) rotateX(0) rotateY(-57.7deg) rotateZ(0deg);
    transform-origin: 100% 70.7%;
  }

  @media ${device.desktopL} {
    top: 34.9%;
    left: -89.15%;
    width: 124%;
    height: 124%;
    transform: perspective(1575px) rotateX(0) rotateY(-57.6deg) rotateZ(0);
    transform-origin: 100% 74%;
  }
`;

export const CanvasImg111 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;

  transform: translateX(5px);
  clip-path: polygon(48% 0%,99% 0%,99% 100%,48% 100%);

  @media ${device.mobile} {
    transform: translateX(5px);
    clip-path: polygon(48% 0%,99% 0%,99% 100%,48% 100%);
  }

  @media ${device.tablet} {
    transform: translateX(9.5px);
    clip-path: polygon(52.6% 0%,100% 0%,100% 100%,52.7% 100%);
  }

  @media ${device.laptop} {
    transform: translateX(9.5px);
    clip-path: polygon(49% 0%, 99% 0%, 99% 100%, 49% 100%);
  }
  
  @media ${device.desktop} {
    transform: translateX(16px);
    clip-path: polygon(48% 0%,99% 0%,99% 100%,48% 100%);
  }

  @media ${device.desktopL} {
    transform: translateX(20px);
    clip-path: polygon(48% 0%,99% 0%,99% 100%,48% 100%);
  }
`;

export const CanvasImg112 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(-90deg);
  transform-origin: 100% 100%;

  clip-path: polygon(64% 0%,100% 0%,100% 99%,64% 99%);

  @media ${device.mobile} {
    clip-path: polygon(63.9% 0%,100% 0%,100% 100%,63.9% 100%);
  }

  @media ${device.tablet} {
    clip-path: polygon(65.6% 0%,100% 0%,100% 100%,65.7% 100%);
  }

  @media ${device.laptop} {
    clip-path: polygon(65% 0%,100% 0%,100% 100%,64.5% 100%);
  }

  @media ${device.desktop} {
    clip-path: polygon(69.1% 0%,100% 0%,100% 100%,69.3% 100%);
  }
  
  @media ${device.desktopL} {
    clip-path: polygon(68.5% 0%,100% 0%,100% 100%,68.4% 100%);
  }
`;

export const CanvasImg113 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;

  transform: rotateY(-90deg) translateX(16px) translateY(0) translateZ(14px);
  clip-path: polygon(98% 0%,100% 0%,100% 41%,98% 41%);

  @media ${device.mobile} {
    transform: rotateY(-90deg) translateX(16px) translateY(0) translateZ(29px);;
    clip-path: polygon(98% 0%,100% 0%,100% 54%,98% 54%);
  }

  @media ${device.tablet} {
    transform: rotateY(-90deg) translateX(16px) translateY(0) translateZ(75px);
    clip-path: polygon(98% 0%,100% 0%,100% 54%,98% 54%);
  }

  @media ${device.laptop} {
    transform: rotateY(-90deg) translateX(15px) translateY(0) translateZ(76px) rotateZ(.1deg);
    clip-path: polygon(98% 0%,100% 0%,100% 54%,98% 54%);
  }

  @media ${device.desktop} {
    transform: rotateY(-90deg) translateX(14px) translateY(-3px) translateZ(126px) scaleY(.98);
    clip-path: polygon(98% 0%,100% 0%,100% 54%,98% 54%);
  }
  
  @media ${device.desktopL} {
    transform: rotateY(-90deg) translateX(16px) translateY(0) translateZ(162px) scaleY(.99);
    clip-path: polygon(98% 0%,100% 0%,100% 54%,98% 54%);
  }
`;

export const CanvasImg114 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  border-left: 1px solid #6666;

  transform: translateX(331px) translateY(0) translateZ(14px) scaleY(1);
  clip-path: polygon(0% 0%,5.5% 0%,5.5% 20%,0% 20%);

  @media ${device.mobile} {
    transform: translateX(464px) translateY(19px) translateZ(32px) rotateX(0) scaleY(1);
    clip-path: polygon(0% 0%, 4% 0%, 4% 13%, 0% 13%);
  }

  @media ${device.tablet} {
    transform: translateX(844px) translateY(28px) translateZ(32px) rotateX(0) scaleY(1);
    clip-path: polygon(0% 0%,4.9% 0%,5% 13%,0% 13%);
  }

  @media ${device.laptop} {
    transform: translateX(805px) translateY(25px) translateZ(32px) rotateX(0) scaleY(1);
    clip-path: polygon(0% 0%,3.6% 0%,3.7% 13%,0% 13%);
  }

  @media ${device.desktop} {
    transform: translateX(1316px) translateY(-1px) translateZ(31px) rotateX(0) scaleY(.97);
    clip-path: polygon(0% 0%,3.6% 0%,3.7% 13%,0% 13%);
  }
  
  @media ${device.desktopL} {
    transform: translateX(1574px) translateY(1px) translateZ(8px) rotateX(0) scaleY(.98);
    clip-path: polygon(0% 0%,3.6% 0%,3.7% 12%,0% 12%);
  }
`;

export const CanvasImg115 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  border-left: 1px solid #6666;

  transform: translateX(323px) translateY(2px) translateZ(6px) rotateX(.2deg);
  clip-path: polygon(0% 46%,4% 46%,4% 55%,0% 55%);

  @media ${device.mobile} {
    transform: translateX(434px) translateY(15.5px) translateZ(15px) rotateX(0deg);
    clip-path: polygon(0% 39%,4.2% 39%,4.2% 49%,0% 49%);
  }

  @media ${device.tablet} {
    transform: translateX(807px) translateY(15.5px) translateZ(11px) rotateX(0deg);
    clip-path: polygon(0% 39%,5% 42%,5.2% 49%,0% 49%);
  }

  @media ${device.laptop} {
    transform: translateX(764px) translateY(15.5px) translateZ(9px) rotateX(0deg);
    clip-path: polygon(0% 42%,3.6% 42%,3.6% 49%,0% 49%);
  }

  @media ${device.desktop} {
    transform: translateX(1277px) translateY(-9.5px) translateZ(8px) rotateX(0deg);
    clip-path: polygon(0% 42%,3.6% 42%,3.6% 49%,0% 49%);
  }
  
  @media ${device.desktopL} {
    transform: translateX(1575px) translateY(-3.5px) translateZ(8px) rotateX(0deg);
    clip-path: polygon(0% 42%,3.6% 42%,3.6% 49%,0% 49%);
  }
`;

export const CanvasImg116 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  border-right: 1px solid #6666;

  transform: translateX(17px) translateY(0) translateZ(-57px) rotateZ(0deg) scaleY(1);
  clip-path: polygon(99% 18%,100% 18%,100% 86%,99% 86%);

  @media ${device.mobile} {
    transform: translateX(23px) translateY(11px) translateZ(-74px) rotateZ(0deg) scaleY(1);
    clip-path: polygon(99% 13%,100% 13%,100% 80%,99% 80%);
  }

  @media ${device.tablet} {
    transform: translateX(23px) translateY(11px) translateZ(-154px) rotateZ(0deg) scaleY(1);
    clip-path: polygon(99% 13%,100% 13%,100% 78%,99% 78%);
  }

  @media ${device.laptop} {
    transform: translateX(20px) translateY(-2px) translateZ(-151px) rotateZ(0deg) scaleY(.97);
    clip-path: polygon(99% 13%,100% 13%,100% 80%,99% 79%);
  }

  @media ${device.desktop} {
    transform: translateX(17px) translateY(-9px) translateZ(-229px) rotateZ(0deg) scaleY(.985);
    clip-path: polygon(99% 13%,100% 13%,100% 75%,99% 75%);
  }
  
  @media ${device.desktopL} {
    transform: translateX(17px) translateY(-1px) translateZ(-296px) rotateZ(0deg) scaleY(.99);
    clip-path: polygon(99% 13%,100% 13%,100% 75%,99% 75%);
  }
`;

export const CanvasImg117 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;
  border-right: 1px solid #6666;

  transform: rotateY(-90deg) translateX(-28px) translateY(8px) translateZ(-1px);
  clip-path: polygon(89% 41%,100% 41%,100% 50%,89% 50%);

  @media ${device.mobile} {
    transform: rotateY(-90deg) translateX(-25px) translateY(14.2px) translateZ(-17px);
    clip-path: polygon(89% 41%,100% 41%,100% 49%,89% 49%);
  }

  @media ${device.tablet} {
    transform: rotateY(-91deg) translateX(-60px) translateY(16.5px) translateZ(-17px);
    clip-path: polygon(89.5% 41%,100% 41%,100% 49%,89.5% 49%);
  }

  @media ${device.laptop} {
    transform: rotateY(-90deg) translateX(-54px) translateY(18.5px) translateZ(-17px);
    clip-path: polygon(88.8% 41%,100% 41%,100% 49%,88.8% 49%);
  }

  @media ${device.desktop} {
    transform: rotateY(-90deg) translateX(-87px) translateY(-3.5px) translateZ(-17px);
    clip-path: polygon(90.2% 41%,100% 41%,100% 49%,90.2% 49%);
  }
  
  @media ${device.desktopL} {
    transform: rotateY(-90deg) translateX(-112px) translateY(4.5px) translateZ(-19px);
    clip-path: polygon(90% 41%,100% 41%,100% 49%,90% 49%);
  }
`;

export const Cube12Wrapper = styled.div`
  z-index: 1;
  position: absolute;
  width: 1400px;
  height: 560px;

  & > div > img {
    image-rendering: -webkit-optimize-contrast;
  }
`

export const Cube12 = styled.div`
  position: absolute;
  z-index: 1;
  transform-style: preserve-3d;
  perspective-origin: 100% 100%;

  top: 15.3%;
  left: -4%;
  width: 23%;
  height: 23%;
  transform: perspective(368px) rotateX(0) rotateY(-51deg) rotateZ(0deg);
  transform-origin: 100% 80%;

  @media ${device.mobile} {
    top: 11.9%;
    left: -10.75%;
    width: 31%;
    height: 31%;
    transform: perspective(490px) rotateX(0) rotateY(-50deg) rotateZ(0deg);
    transform-origin: 100% 84%;
  }

  @media ${device.tablet} {
    top: 21%;
    left: -18.9%;
    width: 55%;
    height: 55%;
    transform: perspective(850px) rotateX(0) rotateY(-50deg) rotateZ(0deg);
    transform-origin: 100% 81%;
  }

  @media ${device.laptop} {
    top: 21%;
    left: -24%;
    width: 60%;
    height: 60%;
    transform: perspective(900px) rotateX(0) rotateY(-51deg) rotateZ(0deg);
    transform-origin: 100% 76%;
  }

  @media ${device.desktop} {
    top: 31.3%;
    left: -34.55%;
    width: 87%;
    height: 87%;
    transform: perspective(1300px) rotateX(0) rotateY(-50deg) rotateZ(0deg);
    transform-origin: 100% 76%;
  }

  @media ${device.desktopL} {
    top: 40%;
    left: -42.2%;
    width: 109%;
    height: 109%;
    transform: perspective(1650px) rotateX(0) rotateY(-50deg) rotateZ(0deg);
    transform-origin: 100% 76%;
  }
`;

export const CanvasImg121 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;

  transform: translateX(5px);
  clip-path: polygon(85% 0%,99% 0%,99% 100%,85% 100%);

  @media ${device.mobile} {
    transform: translateX(5px);
    clip-path: polygon(85% 0%,99% 0%,99% 100%,85% 100%);
  }

  @media ${device.tablet} {
    transform: translateX(8px);
    clip-path: polygon(85% 0%,99% 0%,99% 100%,85% 100%);
  }

  @media ${device.laptop} {
    transform: translateX(9.5px);
    clip-path: polygon(86% 0%,99% 0%,99% 92%,86% 92%);
  }
  
  @media ${device.desktop} {
    transform: translateX(14px);
    clip-path: polygon(86% 0%,99% 0%,99% 92%,85% 92%);
  }

  @media ${device.desktopL} {
    transform: translateX(18px);
    clip-path: polygon(85% 0%,99% 0%,99% 92%,85% 92%);
  }
`;

export const CanvasImg122 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateY(-90deg);
  transform-origin: 100% 100%;

  clip-path: polygon(84% 0%,100% 0%,100% 100%,84% 100%);

  @media ${device.mobile} {
    clip-path: polygon(84% 0%,100% 0%,100% 100%,84% 100%);
  }

  @media ${device.tablet} {
    clip-path: polygon(84% 0%,100% 0%,100% 100%,84% 100%);
  }

  @media ${device.laptop} {
    clip-path: polygon(85% 0%,100% 0%,100% 91%,85% 91%);
  }

  @media ${device.desktop} {
    clip-path: polygon(85% 0%,100% 0%,100% 91%,85% 91%);
  }
  
  @media ${device.desktopL} {
    clip-path: polygon(85% 0%,100% 0%,100% 92%,85% 92%);
  }
`;

export const CanvasImg123 = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  transform-origin: 100% 100%;

  transform: translateX(21px) translateY(0) translateZ(-132px) rotateX(-.5deg) scaleY(1);
  clip-path: polygon(50% 18%,100% 18%,100% 70%,50% 60%);

  @media ${device.mobile} {
    transform: translateX(13px) translateY(6px) translateZ(-35px) rotateX(0deg) scaleY(0.985);
    clip-path: polygon(98% 16%,100% 16%,100% 73%,98% 73%);
  }

  @media ${device.tablet} {
    transform: translateX(13px) translateY(6px) translateZ(-70px) rotateX(0deg) scaleY(0.985);
    clip-path: polygon(98% 18%,102% 18%,100% 75%,98% 75%);
  }

  @media ${device.laptop} {
    transform: translateX(13px) translateY(-3px) translateZ(-108px) rotateX(0deg) scaleY(0.985);
    clip-path: polygon(98% 18%,102% 18%,100% 71%,98% 71%);
  }
  
  @media ${device.desktop} {
    transform: translateX(13px) translateY(-3px) translateZ(-108px) rotateX(0deg) scaleY(0.985);
    clip-path: polygon(98% 18%,102% 18%,100% 71%,98% 71%);
  }

  @media ${device.desktopL} {
    transform: translateX(21px) translateY(-2px) translateZ(-132px) rotateX(0deg) scaleY(0.985);
    clip-path: polygon(98% 18%,102% 18%,100% 71%,98% 71%);
  }
`;