import React, { useState, useMemo, Fragment } from 'react';
import Tippy from '@tippyjs/react';
import { Range, Direction } from 'react-range';

import {
  ChooseItemContainer, SelectedSections, SelectorMainViewContainer,
  SelectedSection, DragThumb, DragArrow, DragThumbContainer,
  DragTrack, DragTrackInnerBlock, ChooseBrickBlock, ChooseBrickIconContainer,
  ChooseBrickText, ChooseItemOptions, ChooseItemOption, ChooseItemOptionImage,
  ChooseItemOptionText, SelectedOptionImage, SelectedOptionContainer,
  SelectedOptionIconBlock, SelectedOptionLabel, SelectedOptionLabelWrapper, AddButtonBlock,
  AddButtonBlockText, SelectorsMainView, TransparentBlock, SelectedSectionContent, SectionTitle,
  ScaleBlock, ScaleMarkContainer, ScaleMark, ScaleText, SelectedOptionIcon, SelectorFooter, Counter
} from './styled';
import dragBrick from 'components/Viewer/icons/drag-brick.svg';
import dragArrow from 'components/Viewer/icons/drag-arrow.svg';
import changeBrick from 'components/Viewer/icons/change-brick.svg';
import removeBrick from 'components/Viewer/icons/remove-brick.svg';
import { scales, brickOptions } from './options';
import { PlusIcon } from './PlusIcon';
import CustomScrollbar from 'components/CustomScrollbar';
import { useWindowSize } from 'hooks/useWindowSize';
import { blockWrongPercentage } from './blockWrongPercentage';
import { redefinePercentages } from 'brickSizes';

export const BrickSelector = ({ brickValues = [], brickRanges: values, onChangeRange, onChangeBrick, onAddBrick }) => {
  const [isSelectorVisible, setIsSelectorVisible] = useState(false);
  const [currentSelectedSection, setCurrentSelectedSection] = useState(null);

  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const defineBlockHeight = (index, array) => {
    if (array.length - 1 === index) {
      return 100 - array[array.length - 2];
    } else {
      if (index === 0) {
        return array[index];
      }

      return array[index] - array[index - 1];
    }
  };

  const handleOpenBrickSelector = ({ currentTarget: { dataset } }) => {
    setIsSelectorVisible(true);
    setCurrentSelectedSection(dataset.sectionId);
  };

  const handleCloseBrickSelector = () => {
    setIsSelectorVisible(false);
    setCurrentSelectedSection(null);
  };

  const handleClickOnOption = (type) => () => {
    const newValues = brickValues.slice();

    if (newValues[currentSelectedSection]) {
      newValues[currentSelectedSection].type = type;
    } else {
      newValues[currentSelectedSection] = { type };

      onAddBrick();
    }

    onChangeBrick(newValues);
    handleCloseBrickSelector();
  };

  const handleClickOnRemove = (index) => () => {
    if (values.length > 1) {
      const newValues = [
        ...brickValues.slice(0, index),
        ...brickValues.slice(index + 1, brickValues.length)
      ];
      onChangeBrick(newValues);

      onChangeRange([...redefinePercentages(values.length - 1)]);
    } else {
      const newValues = brickValues.slice();

      newValues[index].type = null;

      onChangeBrick(newValues)
    }
  };

  const handleAddBrick = () => {
    handleOpenBrickSelector({ currentTarget: { dataset: { sectionId: values.length + 1 } } });
  };

  const renderChooseOptions = brickOptions.map(({ label, type, image }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isCurrentOptionActive = brickValues[currentSelectedSection] && brickValues[currentSelectedSection].type === type;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <ChooseItemOption
        key={type}
        isActive={isCurrentOptionActive}
        onClick={handleClickOnOption(type)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <ChooseItemOptionImage brickImage={image} isActive={isCurrentOptionActive} />

        <SelectedOptionLabelWrapper>
          <ChooseItemOptionText isActive={isCurrentOptionActive}>{label}</ChooseItemOptionText>
        </SelectedOptionLabelWrapper>
      </ChooseItemOption >
    );
  });

  const renderScalesMarks = useMemo(() => scales.map(({ value, label, isBold, percent }) => (
    <ScaleMarkContainer scaleblockheight={value} key={label}>
      {percent && <ScaleText isbold={isBold}>{label}%</ScaleText>}

      <ScaleMark isbold={isBold} />
    </ScaleMarkContainer>
  )), []);

  const { width } = useWindowSize();

  const STEP = 1;
  const MIN = 10;
  const MAX = 90;

  return (
    isSelectorVisible
      ? (
        <ChooseItemContainer>
          <CustomScrollbar leftPosition='17rem'>
            <ChooseItemOptions>
              {renderChooseOptions}
            </ChooseItemOptions>
          </CustomScrollbar>
        </ChooseItemContainer>
      )
      : (
        <SelectorsMainView>
          <SectionTitle>Облицовочный кирпич</SectionTitle>
          <SelectorMainViewContainer>
            <Range
              direction={Direction.Down}
              values={values}
              step={STEP}
              min={MIN}
              max={MAX}
              onChange={(newValues) => {
                if (!blockWrongPercentage(newValues)) {
                  onChangeRange(newValues);
                }
              }}
              renderTrack={({ props, children }) => (
                <DragTrack
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  isWithButton={(values.length < 4 && brickValues.every(({ type }) => !!type))}
                  style={{
                    ...props.style,
                  }}
                >
                  <DragTrackInnerBlock
                    ref={props.ref}
                  >
                    {children}
                  </DragTrackInnerBlock>
                </DragTrack>
              )}
              renderThumb={({ props }) => (
                <DragThumbContainer
                  {...props}
                  style={{
                    ...props.style,
                  }}
                >
                  <DragThumb>
                    <img src={dragBrick} alt='drag' />
                    <DragArrow src={dragArrow} alt='arrow' />
                  </DragThumb>
                </DragThumbContainer>
              )}
            />

            <SelectedSections>
              {
                [...values, null].map((value, index, array) => {
                  const isSelected = Boolean(brickValues[index].type);
                  const selectedOption = brickOptions.find(({ type }) => type === brickValues[index].type);

                  return (
                    <Fragment key={`${index} ${value}`}>
                      <Tippy
                        arrow={false}
                        visible={index === 0 && isTutorial && width > 1000}
                        placement='right'
                        offset={[0, -100]}
                        maxWidth={237}
                        content={<p>Нажмите, чтобы выбрать кирпич</p>}
                      >
                        <SelectedSection
                          height={defineBlockHeight(index, array)}
                          onClick={isSelected ? () => { } : handleOpenBrickSelector}
                          data-section-id={index}
                        >
                          {isSelected
                            ? (
                              <>
                                {index !== 0 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}
                                <SelectedSectionContent>
                                  <SelectedOptionContainer>
                                    <SelectedOptionImage background={selectedOption.image}>
                                      <SelectedOptionIconBlock data-section-id={index} onClick={handleOpenBrickSelector}>
                                        <SelectedOptionIcon src={changeBrick} alt='change' />
                                      </SelectedOptionIconBlock>

                                      <SelectedOptionIconBlock onClick={handleClickOnRemove(index)}>
                                        <SelectedOptionIcon src={removeBrick} alt='remove' />
                                      </SelectedOptionIconBlock>
                                    </SelectedOptionImage>

                                    <SelectedOptionLabelWrapper>
                                      <SelectedOptionLabel>{selectedOption.label}</SelectedOptionLabel>
                                    </SelectedOptionLabelWrapper>
                                  </SelectedOptionContainer>
                                </SelectedSectionContent>
                                {index !== array.length - 1 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}
                              </>
                            )
                            : (
                              <>
                                {index !== 0 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}
                                <SelectedSectionContent>
                                  <ChooseBrickBlock>
                                    <ChooseBrickIconContainer>
                                      <PlusIcon />
                                    </ChooseBrickIconContainer>

                                    <ChooseBrickText>Нажмите, чтобы выбрать кирпич</ChooseBrickText>
                                  </ChooseBrickBlock>
                                </SelectedSectionContent>

                                {index !== array.length - 1 && (
                                  <TransparentBlock
                                    id='transparent-block'
                                  />
                                )}
                              </>
                            )
                          }
                        </SelectedSection>
                      </Tippy>
                    </Fragment>
                  );
                })
              }

            </SelectedSections>
            <ScaleBlock isWithButton={(values.length < 4 && brickValues.every(({ type }) => !!type))}>
              {renderScalesMarks}
            </ScaleBlock>
          </SelectorMainViewContainer>

          {(values.length < 4 && brickValues.every(({ type }) => !!type)) &&
            <SelectorFooter>
              <Counter>{values.length+1} / 5</Counter>

              <AddButtonBlock onClick={handleAddBrick}>
                <ChooseBrickIconContainer>
                  <PlusIcon />
                </ChooseBrickIconContainer>

                <AddButtonBlockText>Добавить кирпич</AddButtonBlockText>
              </AddButtonBlock>
            </SelectorFooter>
          }

        </SelectorsMainView>
      )
  )
};
