import React from 'react';
import { SizeIcon07nf } from './icons/SizeIcon07nf';
import { SizeIcon09nf } from './icons/SizeIcon09nf';
import { SizeIcon1nf } from './icons/SizeIcon1nf';
import { SizeIcon14nf } from './icons/SizeIcon14nf';

export const sizeOptions = [
  {
    firstLabel: '0.7 NF',
    secondLabel: '250x85x65',
    value: '0.7',
    icon: <SizeIcon07nf />,
  },
  {
    firstLabel: '0.9 NF',
    secondLabel: '250x85x88',
    value: '0.9',
    icon: <SizeIcon09nf />,
  },
  {
    firstLabel: '1 NF',
    secondLabel: '250x120x65',
    value: '1',
    icon: <SizeIcon1nf />,
  },
  {
    firstLabel: '1.4 NF',
    secondLabel: '250x120x88',
    value: '1.4',
    icon: <SizeIcon14nf />,
  },
];
