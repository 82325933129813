import styled from 'styled-components';

export const PDFContainer = styled.div`
  flex-direction: column;
  height: 2520px;
  
  position: fixed;
  
  display: flex;
  
  transform: translate(100vw, calc(var(--vh, 1vh) * 100));
`;

export const PageContainer = styled.div`
  height: 1260px;
  width: 891px;

  overflow: hidden;

  display: flex;
  flex-direction: column;

  #first-page-canvas, #second-page-canvas {
    margin: 0 auto;
  }
`;

export const FirstPageTableContainer = styled.div`
  min-height: 340px;
  margin-bottom: 40px;

  margin-left: 48px;
  display: flex;
  width: 593px;
`;

export const ValueItem = styled.div`
  display: flex;
  margin-left: 48px;
  margin-top: 20px;
  margin-bottom: 19px;

  width: 793px;
`;

export const ValueLabel = styled.p`
  margin: 0;
  margin-right: 16px;
  
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
`;

export const ValueData = styled(ValueLabel)`
  font-weight: bold;
`;

export const SecondPageTableContainer = styled.div`
  min-height: 220px;
`;

export const SecondPageHeading = styled.h2`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;

  text-align: center;
  text-transform: uppercase;

  color: #C85F37;
  
  margin: 0;
  margin-bottom: 46px;
`;

export const SecondPageTable = styled.div`
  margin-top: 46px;

  margin-left: 48px;
  width: 593px;
`;
