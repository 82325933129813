import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

export const BondSelectorContainer = styled.div`
  margin: .75rem .75rem .8125rem .9375rem;

  height: calc(100% - 1.5675rem);
  width: 100%;
`;

export const BondOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const BondOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.6875rem;
  border-radius: 15px;
  margin-bottom: 16px;
  margin-right: 16px;

  box-sizing: border-box;
  border: 1px solid transparent;

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      border: 1px solid #F48120;
    }
  `)}

  ${ifProp({ isActive: true }, css`
    background: #27282A;
  `)}
`;

export const BondOptionIconContainer = styled.div`
  flex-basis: 6.75rem;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    .marked {
      fill: #959595;
    }

    .main {
      fill: #C4C4C4;
    }
  }

  ${ifProp({ isActive: true }, css`
    svg .main {
      fill: #F48120;
    }
  `)}
`;

export const BondOptionText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-basis: 4.96875rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const BondOptionFirstLabel = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  line-height: 1rem;

  color: white;
  margin: 0;
  
  margin-top: 0.175rem;
`;

export const BondOptionSecondLabel = styled(BondOptionFirstLabel)`
  margin-top: 0.4375rem;
  font-weight: 400;
  font-size: 12px;
  text-transform: none;
`;

export const ChooseBondButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #F48120;
  border-radius: 0.1875rem;

  cursor: pointer;
  position: absolute;
`;

export const BondSelectorHeader = styled.div`
  height: 2.25rem;
  display: flex;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);
  flex-shrink: 0;
`;

export const BondSelectorHeaderText = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  color: #FFFFFF;

  opacity: 0.5;
  margin: 0;

  margin-left: .8125rem;
  margin-top: .625rem;
  margin-bottom: .5rem;
`;
