import React, { Fragment, useState, useCallback, useMemo } from 'react';
import intersection from 'ramda/es/intersection'
import {
  SizeSelectorContainer, SizeOption, SizeOptionIconContainer,
  SizeOptionText, SizeOptionFirstLabel, SizeOptionSecondLabel,
  SizeOptions, SizeIsNotAvailable, SizeOptionsTitle,
} from './styled';
import { sizeOptions } from './options';
import { brickOptions } from '../BrickSelector/options';

export const SizeSelector = ({ onChangeSize, currentSize, brickValues }) => {
  const handleClickOnOption = useCallback((size) => () => {
    onChangeSize(size);
  }, [onChangeSize]);


  const availableSizes = useMemo(() => {
    const currentSizes = brickOptions
      .filter(({ type }) => brickValues.some(({ type: brickValue }) => brickValue === type))
      .map(({ sizes }) => sizes)
      .sort((a, b) => a.length - b.length);

    if (!currentSizes || currentSizes.length === 0) return ['1', '0.7', '1.4'];

    if (currentSizes.length === 1) {
      return currentSizes[0];
    }

    return intersection(...currentSizes);
  }, [brickValues]);

  const renderOptions = sizeOptions.map(({ icon, firstLabel, secondLabel, value }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = value === currentSize;

    const isOptionNotAvailable = !availableSizes.some((size) => size === value);

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <Fragment key={value}>
        {isOptionNotAvailable && <SizeIsNotAvailable />}
        <SizeOption
          isActive={isActiveOption}
          onClick={handleClickOnOption(value)}
          onMouseEnter={handleMouseEnterOption}
          onMouseLeave={handleMouseLeaveOption}
        >
          <SizeOptionIconContainer isActive={isActiveOption}>
            {icon}
          </SizeOptionIconContainer>

          <SizeOptionText>
            <SizeOptionFirstLabel isActive={isActiveOption}>{firstLabel}</SizeOptionFirstLabel>

            <SizeOptionSecondLabel isActive={isActiveOption}>{secondLabel}</SizeOptionSecondLabel>
          </SizeOptionText>
        </SizeOption>
      </Fragment>
    );
  });

  return (
    <SizeSelectorContainer>
      <SizeOptionsTitle>
        Формат кирпича
      </SizeOptionsTitle>
      <SizeOptions>
        {renderOptions}
      </SizeOptions>
    </SizeSelectorContainer>
  );
};
