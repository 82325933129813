import styled from 'styled-components';
import { BraerPremiumImage, ChooseItemOptionImage } from 'components/BrickSelector/styled';

export const TableHead = styled.div`
  border-bottom: 1px solid rgb(36,32,33, 0.5);
  background: #434748;
  color: white;
  font-size: bold;

  min-height: 41px;
`;

export const TableHeadRow = styled.div`
  padding: 15px;
`;

export const TableHeadCell = styled.div`
  height: 100%;
  overflow: visible; 

  display: flex !important;
  align-items: flex-end;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;

  p {
    margin: 0;
    width: 350px;

    font-family: 'Segoe UI', 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    padding-left: 16px;
  }
`;

export const TableBody = styled.div``;

export const TableBodyRow = styled.div`
  min-height: 61px;
  border-bottom: 1px solid rgb(36,32,33, 0.5);
  width: 100% !important;
`;

export const TableBodyCell = styled.div`
  display: flex !important;
  align-items: center;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;

  ${ChooseItemOptionImage} {
    min-width: 102px;
    width: 102px;
    height: 32px;
  }

  ${BraerPremiumImage} {
    margin-left: 2.8125rem;
  }
`;

export const Image = styled.div`
  border-radius: 1rem;
  border-color: transparent;
  background: url(${({ brickImage }) => brickImage}) no-repeat;
  background-size: cover;

  width: 100%;
  height: 2rem;

  margin: 0 .875rem 0 0;

  box-sizing: border-box;
`;
