import React from 'react';
import Modal from 'react-modal';
import closeIcon from './close-icon.svg';
import { ModalText, CloseModalIcon, Input, FormButton } from './styled';
import { AppContext } from 'App';

export const FormModal = ({ isModalOpen, handleCloseModal, handleDownloadImage }) => {
  Modal.setAppElement('#root');

  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [validation, setValidation] = React.useState(false);
  const form = React.useRef();

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 250,
      padding: 24,
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      background: '#535758',
      border: '0 none'
    },
    overlay: {
      zIndex: 99,
      background: 'rgba(0, 0, 0, 0.7)',
    },
  };

  const {
    brickValues,
    brickRanges,
    currentSeamType: seamSize,
    currentSeamColor: seamColor,
    currentBond: bondType,
    currentSize: brickSize,
  } = React.useContext(AppContext)

  const sendEmail = async () => {
    if (!name || !email) {
      setValidation(true);
    } else {
      const reqBody = `name=${name}&email=${email}&url=${encodeURI(`${window.location.origin}/image?bricks=${brickValues.map(({ type }) => type).filter(item => !!item).join(',')
        };brickRanges=${brickRanges.join(',')
        };bond=${bondType};seamSize=${seamSize};seamColor=${seamColor};size=${brickSize}`)}`

      await handleDownloadImage();
      fetch('/email_send.php', {
        method: 'POST',
        body: reqBody,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
      });
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
    >
      <CloseModalIcon src={closeIcon} onClick={handleCloseModal} />
      <ModalText>Введите имя и email для скачивания JPG</ModalText>

      <form id='form' ref={form} action="email_send.php" method='POST'>
        <Input name="name" placeholder="Имя" type="text" value={name} onChange={(e) => setName(e.target.value)} validation={validation && !name}></Input>
        <Input name="email" placeholder="Email" type="text" value={email} onChange={(e) => setEmail(e.target.value)} validation={validation && !email}></Input>
      </form>
      <FormButton onClick={sendEmail}>Скачать</FormButton>

    </Modal>
  )
};