import React from 'react';
import pdfLogo from './braer-logo.jpg';
import {
  HeaderContainer, HeaderLogoBlock, HeaderLogo
} from './styled';

export const Header = () => (
  <HeaderContainer>
    <HeaderLogoBlock>
      <HeaderLogo src={pdfLogo} />
    </HeaderLogoBlock>
  </HeaderContainer>
);