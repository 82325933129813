import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';

export const SeamSelectors = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const SeamTypeSelectorContainer = styled.div`
  margin: .75rem .75rem .625rem .9375rem;

  display: flex;
  flex-direction: column;
  min-height: 300px;
`;

export const SeamSelectorHeader = styled.div`
  height: 2.25rem;
  display: flex;
  position: sticky;
  top: 0;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);
  flex-shrink: 0;
`;

export const SeamSelectorHeaderText = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  color: #FFFFFF;

  opacity: 0.5;
  margin: 0;

  margin-left: .8125rem;
  margin-top: .625rem;
  margin-bottom: .5rem;
`;

export const SeamColorSelectorContainer = styled(SeamTypeSelectorContainer)`
  margin-top: 0;
  margin-bottom: 1rem;

  min-height: calc(100% - 16px);
`;

export const SeamTypeOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SeamOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.6875rem;
  border-radius: 15px;
  margin-bottom: 16px;
  margin-right: 16px;

  box-sizing: border-box;
  border: 1px solid transparent;

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      border: 1px solid #F48120;
    }
  `)}

  ${ifProp({ isActive: true }, css`
    background: #27282A;
  `)}
`;

export const SeamOptionIconContainer = styled.div`
  flex-basis: 6.75rem;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    margin-top: 1.375rem;
    margin-left: 2.25rem;
    color: #f4f4f4;
  }

  svg path {
    fill: #f4f4f4;
  }

  ${ifProp({ isActive: true }, css`
    svg {
      color: #F48120;
    }

    svg path {
      fill: #F48120;
    }
  `)}
`;

export const SeamOptionText = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 4.96875rem;
  flex-grow: 1;
  flex-shrink: 0;

  margin-left: 2rem;
`;

export const SeamOptionLabel = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  line-height: 1rem;

  color: white;
  margin: 0;
  
  margin-top: 1.775rem;
`;

export const ChooseSeamTypeButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #F48120;
  border-radius: 0.1875rem;

  position: absolute;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-left: 1.7rem;
`;

export const SeamColorOptions = styled(SeamTypeOptions)`
  min-height: 100%;
`;

export const SeamColorOption = styled(SeamOption)`
  align-items: center;
  height: 4.6875rem;
`;

export const SeamColor = styled.div`
  width: 3rem;
  height: 3rem;

  flex-grow: 0;
  flex-shrink: 0;

  border-radius: 50%;
  background-color: ${({ color }) => color};
  box-sizing: border-box;
  margin-left: .875rem;
  margin-right: 1.1875rem;
`;

export const SeamColorText = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  line-height: 1rem;

  color: white;
  margin: 0;
  
  margin-top: 0.175rem;

  ${ifProp({ isActive: true }, css`
    color: #F48120;
  `)}
`;

export const SeamColorButtonContainer = styled.div`
  flex-grow: 1;

  display: flex;
  justify-content: flex-end;
`;

export const SeamColorButton = styled(ChooseSeamTypeButton)`
  margin: 0;
  position: static;

  margin-right: 1rem;
  width: 5rem;
  height: 1.5rem;
`;

export const SeamContentWrapper = styled.div`
  margin-bottom: 16px;
  height: 100%;
`;