import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { createBrickLayout } from 'createBrickLayout';

export const createPDF = async (valuesForBricks, brickSize, seamSize, seamImage, bondType) => {
  const doc = new jsPDF({
    unit: 'in',
  });

  const firstCanvas = document.querySelector('#first-page-canvas');

  await createBrickLayout(firstCanvas, valuesForBricks, brickSize, seamSize, seamImage, bondType);

  const firstImage = await html2canvas(document.querySelectorAll('#pdf-container > div')[0]);

  doc.addImage(firstImage.toDataURL('image/png'), 'PNG', 0, 0, 8, 11, '1', 'NONE');

  doc.save("download.pdf");
};
