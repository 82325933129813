import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { ScrollbarThumb, ScrollbarTrack } from './styled';

export const CustomScrollbar = ({ children, leftPosition }) => {
  return (
    <Scrollbars
      hideTracksWhenNotNeeded={true}
      thumbSize={32}
      renderTrackVertical={({ style, ...props }) => <ScrollbarTrack {...props} style={{ ...style, ...{ left: leftPosition } }} className="track-vertical" />}
      renderThumbVertical={({ style, ...props }) => (
        <ScrollbarThumb {...props} style={style} className="thumb-vertical" />
      )}
    >
      {children}
    </Scrollbars>
  );
};