import React from 'react';
import Modal from 'react-modal';
import closeIcon from './close-icon.svg';
import { ModalText, ModalButtons, ModalButton, ModalButtonDark, CloseModalIcon } from './styled';

export const DownloadModal = ({ isModalOpen, handleCloseModal, handleDownloadPDF, handleOpenForm }) => {
  Modal.setAppElement('#root');

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: 250,
      padding: 24,
      borderRadius: 15,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      background: '#535758',
      border: '0 none'
    },
    overlay: {
      zIndex: 99,
      background: 'rgba(0, 0, 0, 0.7)',
    },
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={handleCloseModal}
      style={customStyles}
    >
      <CloseModalIcon src={closeIcon} onClick={handleCloseModal} />
      <ModalText>Скачать результат</ModalText>

      <ModalButtons>
        <ModalButtonDark onClick={handleDownloadPDF}>PDF</ModalButtonDark>

        <ModalButton onClick={handleOpenForm}>JPG (архитекторам)</ModalButton>
      </ModalButtons>
    </Modal>
  )
};