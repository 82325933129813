import styled, { createGlobalStyle } from 'styled-components';

export const AppContainer = styled.main`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-wrap: nowrap;
  box-sizing: border-box;
  background: #2a2b2c;

  overflow: hidden;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    position: relative;
    height: calc(var(--vh, 1vh) * 100);
    padding: 0;

    z-index: 99;
  }
`;

export const GlobalStyle = createGlobalStyle`
  font-size: 16px;
`;
