import React, { useState, useCallback } from 'react';
import {
  SeamTypeSelectorContainer, SeamColorSelectorContainer, SeamSelectors,
  SeamSelectorHeader, SeamSelectorHeaderText, SeamOption, SeamOptionIconContainer,
  SeamOptionText, SeamOptionLabel, SeamTypeOptions, SeamColorOptions,
  SeamColor, SeamColorText, SeamColorOption, SeamContentWrapper,
} from './styled';
import { seamTypesOptions, seamColorOptions } from './options';
import CustomScrollbar from 'components/CustomScrollbar';

export const SeamSelector = ({ currentSeamType, currentSeamColor, onChangeSeamType, onChangeSeamColor }) => {
  const handleClickOnSeamTypeOption = useCallback((seamType) => () => {
    onChangeSeamType(seamType);
  }, [onChangeSeamType]);

  const handleClickOnSeamColorOptions = useCallback((seamColor) => () => {
    onChangeSeamColor(seamColor);
  }, [onChangeSeamColor]);

  const renderSeamTypeOptions = seamTypesOptions.map(({ icon, label, value }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = value === currentSeamType;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <SeamOption
        key={value}
        isActive={isActiveOption}
        onClick={handleClickOnSeamTypeOption(value)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <SeamOptionIconContainer isActive={isActiveOption}>
          {icon}
        </SeamOptionIconContainer>

        <SeamOptionText>
          <SeamOptionLabel isActive={isActiveOption}>{label}</SeamOptionLabel>
        </SeamOptionText>
      </SeamOption>
    );
  });

  const renderSeamColorOptions = seamColorOptions.map(({ image, colorCode, label }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = colorCode === currentSeamColor;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <SeamColorOption
        key={colorCode}
        isActive={isActiveOption}
        onClick={handleClickOnSeamColorOptions(colorCode)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <SeamColor seamImage={image} isActive={isActiveOption} />

        <SeamColorText isActive={isActiveOption}>{label}</SeamColorText>
      </SeamColorOption>
    );
  });

  return (
    <SeamSelectors>
      <SeamTypeSelectorContainer>
        <SeamSelectorHeader>
          <SeamSelectorHeaderText>
            Тип шва
          </SeamSelectorHeaderText>
        </SeamSelectorHeader>

        <SeamTypeOptions>
          {renderSeamTypeOptions}
        </SeamTypeOptions>
      </SeamTypeSelectorContainer>


      <SeamSelectorHeaderText>
        Цвет шва
      </SeamSelectorHeaderText>
      <SeamContentWrapper>
        <CustomScrollbar leftPosition='18.5rem'>
          <SeamColorSelectorContainer>
            <SeamColorOptions>
              {renderSeamColorOptions}
            </SeamColorOptions>
          </SeamColorSelectorContainer>
        </CustomScrollbar>
      </SeamContentWrapper>
    </SeamSelectors>
  );
};
