import styled, { css } from 'styled-components';

export const ModalText = styled.span`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  width: 100%;
  text-transform: uppercase;
  color: white;
  opacity: 0.5;

  text-align: center;
`;

export const ModalButtons = styled.div`
  margin-top: 2rem;
  padding-left: 2rem;
  padding-right: 46px;

  display: flex;
  justify-content: space-between;
`;

export const CloseModalIcon = styled.img`
  position: absolute;
  top: -19%;
  left: 99%;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  width: 80px;
  height: 48px;

  background: #F48120;
  border-radius: 15px;

  outline: none;
  border: 0;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1rem;

  color: #FFFFFF;
  cursor: pointer;
`;

export const Input = styled.input`
  background: #27282A;
  border-radius: 15px;
  color: white;
  border: 0 none;
  padding: 8px 0 8px 12px;
  width: 100%;
  margin-top: 12px;
  &:focus {
    outline: none;
    border: 1px solid #F48120;
  }

  ${({ validation }) => validation && css`
    border: 1px solid red;
  `}
`;

export const FormButton = styled.button`
  background: #F48120;
  border-radius: 15px;
  color: white;
  border: 0 none;
  padding: 8px 12px 8px 12px;
  margin-top: 12px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

export const ModalButtonDark = styled(ModalButton)`
  background: #27282A;
`;
