const imagePathStart = `${process.env.PUBLIC_URL}/images`;

export const brickOptions = [
  {
    sizes: ['1'],
    label: 'Баварская кладка бордо Терра',
    type: 'bav-bor-terra',
    image: `${imagePathStart}/textures/bav-bor-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-bor-terra/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Баварская кладка бордо Гладкий',
    type: 'bav-bordeau-smooth',
    image: `${imagePathStart}/textures/bav-bordeau-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-bordeau-smooth-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Баварская кладка Кора дуба',
    type: 'bav-oak',
    image: `${imagePathStart}/textures/bav-oak.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-oak-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Баварская кладка Кора дуба + песок',
    type: 'bav-oak-sand',
    image: `${imagePathStart}/textures/bav-oak-sand.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-oak-sand-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Баварская кладка Рифленый',
    type: 'bav-reef',
    image: `${imagePathStart}/textures/bav-reef.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-reef-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Баварская кладка Рифленый + песок',
    type: 'bav-reef-sand',
    image: `${imagePathStart}/textures/bav-reef-sand.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-reef-sand-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Баварская кладка Гладкий',
    type: 'bav-smooth',
    image: `${imagePathStart}/textures/bav-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-smooth-14/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Баварская кладка Терра',
    type: 'bav-terra',
    image: `${imagePathStart}/textures/bav-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bav-terra-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1', '1.4'],
    label: 'Бордо гладкий',
    type: 'bor-smooth',
    image: `${imagePathStart}/textures/bor-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bor-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bor-smooth-14/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Бордо терра',
    type: 'bor-terra',
    image: `${imagePathStart}/textures/bor-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/bor-terra-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'БРАЕР КЛАДКА LIMITED ТЕРРА',
    type: 'limited-terra',
    image: `${imagePathStart}/textures/limited-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/limited-terra/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'БРАЕР КЛАДКА LIMITED РИФ',
    type: 'limited-reef',
    image: `${imagePathStart}/textures/limited-reef.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/limited-reef/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Браер глосса',
    type: 'braer-glossa',
    image: `${imagePathStart}/textures/braer-glossa.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/braer-glossa-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Браер глосса Рифленый',
    type: 'braer-glossa-reef',
    image: `${imagePathStart}/textures/braer-glossa-reef.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/braer-glossa-reef-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Браер глосса Терра',
    type: 'braer-glossa-terra',
    image: `${imagePathStart}/textures/braer-glossa-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/braer-glossa-terra-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Браер Мокко Гладкий',
    type: 'braer-mocha-smooth',
    image: `${imagePathStart}/textures/braer-mocha-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/braer-mocha-smooth-1/${index + 1}.jpg`),
  },
  {
    sizes: ['1'],
    label: 'Браер Мокко Терра',
    type: 'braer-mocha-terra',
    image: `${imagePathStart}/textures/braer-mocha-terra.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/braer-mocha-terra-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Коричневый гладкий',
    type: 'brown-smooth',
    image: `${imagePathStart}/textures/brown-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/brown-smooth-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '1'],
    label: 'Коричневый рифленый',
    type: 'brown-groove',
    image: `${imagePathStart}/textures/brown-groove.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/brown-groove-1/${index + 1}.jpg`),
  },
  {
    sizes: ['0.7', '0.9', '1', '1.4'],
    label: 'Красный гладкий',
    type: 'red-smooth',
    image: `${imagePathStart}/textures/red-smooth.jpg`,
    imagesPaths: new Array(6).fill(null).map((_, index) => `${imagePathStart}/red-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(6).fill(null).map((_, index) => `${imagePathStart}/red-smooth-14/${index + 1}.jpg`),
  },
];

export const scales = [
  {
    label: '10',
    value: 10,
    percent: true,
  },
  {
    label: '20',
    value: 10,
    isBold: true,
    percent: true,
  },
  {
    label: '30',
    value: 10,
    percent: true,
  },
  {
    label: '40',
    value: 10,
    percent: true,
  },
  {
    label: '50',
    value: 10,
    isBold: true,
    percent: true,
  },
  {
    label: '60',
    value: 10,
    percent: true,
  },
  {
    label: '70',
    value: 10,
    percent: true,
  },
  {
    label: '80',
    value: 10,
    isBold: true,
    percent: true,
  },
  {
    label: '90',
    value: 10,
    percent: true,
  },
];