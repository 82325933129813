import React, { useRef, useEffect, useCallback } from 'react';
import { useQueryParam } from 'use-query-params';
import {
  ViewerContainer, ViewerCanvas,
} from './styled';

import { createBrickLayout } from 'createBrickLayout';
import { seamColorOptions } from 'components/SeamSelector/options';
import { useWindowSize } from 'hooks/useWindowSize';
import { defineBricksPercentage } from 'brickSizes';

export const Image = () => {

  const { height } = useWindowSize();

  const [brickValues] = useQueryParam('bricks');
  const [seamSize] = useQueryParam('seamSize');
  const [brickSize] = useQueryParam('size');
  const [seamColor] = useQueryParam('seamColor');
  const [bondType] = useQueryParam('bond');
  const [brickRanges] = useQueryParam('brickRanges');

  const timer = useRef();

  const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).imagePath;
  const valuesForBricks = defineBricksPercentage(brickValues.split(',').map(i => ({ type: i })), brickRanges.split(',').map(i => Number(i)), brickSize);

  const canvasRef = useRef(null);
  const viewerContainerRef = useRef(null);

  const setCanvasSizes = useCallback(() => {
    if (canvasRef.current && viewerContainerRef.current) {
      canvasRef.current.width = viewerContainerRef.current.offsetWidth;
      canvasRef.current.height = viewerContainerRef.current.offsetHeight;
    }
    // eslint-disable-next-line
  }, [canvasRef.current, viewerContainerRef.current]);

  useEffect(
    () => {
      setCanvasSizes();
    },
    // eslint-disable-next-line
    [height, setCanvasSizes],
  );

  useEffect(() => {
    if (height) {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout((async () => {
        await createBrickLayout(canvasRef.current, valuesForBricks, brickSize, seamSize, seamImage, bondType);
      }), 300);
    }
    // eslint-disable-next-line
  }, [bondType, brickSize, seamSize, seamImage, height, JSON.stringify(valuesForBricks), setCanvasSizes]);

  return (
    <ViewerContainer ref={viewerContainerRef}>
      <ViewerCanvas width={1920} height={1080} ref={canvasRef}></ViewerCanvas>
    </ViewerContainer>
  );
};
