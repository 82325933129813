import React, { useContext } from 'react';
import { AppContext } from 'App';
import Header from './Header';
import { PageContainer, FirstPageTableContainer, ValueItem, ValueLabel, ValueData, PDFContainer } from './styled';
import { defineBricksPercentage } from 'brickSizes';
import { seamTypesOptions } from 'components/SeamSelector/options';
import { bondOptions } from 'components/BondSelector/options';
import Table from './Table';
import { createPreviewTableData } from './Table/createTableData';

const FirstPagePDF = () => {
  const {
    brickValues,
    brickRanges,

    currentSeamType: seamSize,
    currentBond: bondType,
    currentSize: brickSize,

  } = useContext(AppContext);

  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const seamTypeLabel = seamTypesOptions.find(({ value }) => value === seamSize).label;
  const currentBond = bondOptions.find(({ value }) => value === bondType)
  const bondTypeLabel = `${currentBond.firstLabel}, ${currentBond.secondLabel}`;

  const tableData = createPreviewTableData(valuesForBricks, brickSize);

  return (
    <PageContainer>
      <Header />

      <canvas id='first-page-canvas' height='616' width='801' />

      <ValueItem>
        <ValueLabel>
          Перевязка: {bondTypeLabel}
        </ValueLabel>

        <ValueLabel>
          Шов: {seamTypeLabel}
        </ValueLabel>
      </ValueItem>
      <FirstPageTableContainer>
        <Table isWithImages data={tableData} />
      </FirstPageTableContainer>

      <ValueData style={{ position: 'absolute', top: 776, right: 0 }}>
        OOO «ТД БРАЕР» <br />
        117186, Москва, ул. Нагорная, д.18, к.4 <br />
        ТЕЛ./ФАКС: +7(495)645-71-20<br />
        E-MAIL: td@braer.ru <br />
      </ValueData>
    </PageContainer>
  );
};

export const PDF = () => (
  <PDFContainer id='pdf-container'>
    <FirstPagePDF />
  </PDFContainer>
);
