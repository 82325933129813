import React, { useContext, useMemo, useRef, useEffect, useCallback, useState} from 'react';
import { default as VendorLoader } from 'react-loader-spinner';
import {
  ViewerContainer, TutorialContainer, TutorialHeading, ViewerCanvas,
  ImagesBlock, ImageBlock, ImageText, Image, Developer, Window,
  ModeWrapper, ModeButton, ArrowLeft, ArrowRight,
  Viewer3dRoot, House, House1Image, CubesWrapper1,
  Cube11Wrapper, Cube11, CanvasImg111, CanvasImg112, CanvasImg113,
  CanvasImg114, CanvasImg115, CanvasImg116, CanvasImg117,
  Cube12Wrapper, Cube12, CanvasImg121, CanvasImg122, CanvasImg123,
} from './styled';

import { AppContext } from 'App';
import { createBrickLayout } from 'createBrickLayout';
import { seamColorOptions } from 'components/SeamSelector/options';
import { useWindowSize } from 'hooks/useWindowSize';
import { defineBricksPercentage } from 'brickSizes';

import { LoaderContainer, LoaderText } from 'components/Navigation/Loader/styled';

import image1 from './images/1.jpg';
import image2 from './images/2.png';
import image3 from './images/3.png';
import image4 from './images/4.JPG';
import image5 from './images/5.png';
import image6 from './images/6.png';
import window1 from './images/window_new01.png';
import window2 from './images/window_new02.png';
import window3 from './images/window_new03.png';
import window4 from './images/window_new04.png';

import house1 from './images/house1.png';

const windowsConfig = {
  '': '',
  '#F5FFFA': window1,
  '#8B4513': window2,
  '#D2691E': window3,
  '#696969': window4,
};

export const Viewer = () => {
  const {
    brickValues,
    brickRanges,
    currentSeamType: seamSize,
    currentSeamColor: seamColor,
    currentWindowColor: windowColor,
    currentBond: bondType,
    currentSize: brickSize,

    isMobileTutorial,
    isMobileHeading,
  } = useContext(AppContext)

  const { width, height } = useWindowSize();

  const timer = useRef();

  const seamImage = seamColorOptions.find(({ colorCode }) => colorCode === seamColor).imagePath;
  const valuesForBricks = defineBricksPercentage(brickValues, brickRanges, brickSize);

  const canvasRef = useRef(null);
  const viewerContainerRef = useRef(null);

  const totalHouses = 1;
  const [imageData, setImageData] = useState();
  const [mode, setMode] = useState('2d');
  const [house, setHouse] = useState(1);
  const [preloader, setPreloader] = useState(false);
  const [windowScale, setWindowScale] = useState(1);

  const isTutorial = useMemo(() => brickValues.every(({ type }) => !type), [brickValues]);

  const setCanvasSizes = useCallback(() => {
    if (canvasRef.current && viewerContainerRef.current) {
      canvasRef.current.width = mode === '3d' ? 4000 : viewerContainerRef.current.offsetWidth;
      canvasRef.current.height = mode === '3d' ? 2160 : viewerContainerRef.current.offsetHeight;
    }
    // eslint-disable-next-line
  }, [canvasRef.current, viewerContainerRef.current, mode]);

  useEffect(
    () => {
      if (mode === '3d') {
        switch (true) {
          case (width > 1800):
            setWindowScale(Math.min((height / 875), ((width - 399) / 1400)));
            break;
          case (width > 1500):
            setWindowScale(Math.min((height / 687.5), ((width - 399) / 1100)));
            break;
          case (width > 1000):
            setWindowScale(Math.min((height / 472.5), ((width - 399) / 756)));
            break;
          case (width > 768):
            setWindowScale(Math.min(((height - 110) / 472.5), (width / 756)));
            break;
          case (width > 325):
            setWindowScale(Math.min(((height - 110) / 265.63), (width / 425)));
            break;
          default:
            setWindowScale(Math.min(((height - 110) / 265.63), 1));
        }
      }
    },
    [mode, width, height],
  );

  useEffect(
    () => {
      if (mode === '3d') {
        setMode('2d');
      }
    },
    // eslint-disable-next-line
    [windowColor, isTutorial],
  );

  useEffect(
    () => {
      setCanvasSizes();
    },
    // eslint-disable-next-line
    [height, isTutorial, setCanvasSizes, mode],
  );

  useEffect(() => {
    if (!isTutorial && !isMobileTutorial && height) {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout((async () => {
        setPreloader(true);

        await createBrickLayout(canvasRef.current, valuesForBricks, brickSize, seamSize, seamImage, bondType, false, mode === '3d');

        mode === '3d' && canvasRef.current.toBlob(blob => {
          const link = URL.createObjectURL(blob);
          setImageData(link);
        }, 'image/jpeg', 0.95);

      }), 300);
    }
    // eslint-disable-next-line
  }, [isTutorial, bondType, brickSize, seamSize, seamImage, height, JSON.stringify(valuesForBricks), setCanvasSizes, mode]);

  useEffect(() => {
    setPreloader(false);
  }, [imageData]);

  return (
    <ViewerContainer ref={viewerContainerRef} isMobileTutorial={isMobileTutorial || isMobileHeading}>
      {
        (isTutorial || isMobileTutorial || isMobileHeading) && (
          <TutorialContainer isTutorial={true}>
            {(!isMobileTutorial) && <TutorialHeading>Создай свою уникальную кладку BRAER</TutorialHeading>}
            <ImagesBlock>
              <ImageBlock>
                <ImageText>
                  Смешивай от 2 до 5 видов кирпича и меняй их соотношение в кладке
                </ImageText>
                <Image src={image1} />
              </ImageBlock>
              <ImageBlock>
                <ImageText>
                  Меняй форматы кирпича
                </ImageText>
                <Image src={image2} />
              </ImageBlock>
              <ImageBlock>
                <ImageText>
                  Изменяй толщину швов между кирпичами
                </ImageText>
                <Image src={image3} />
              </ImageBlock>
              <ImageBlock>
                <ImageText>
                  Выбирай цвет швов
                </ImageText>
                <Image src={image4} />
              </ImageBlock>
              <ImageBlock>
                <ImageText>
                  Экспериментируй с перевязками
                </ImageText>
                <Image src={image5} />
              </ImageBlock>
              <ImageBlock>
                <ImageText>
                  Скачивай результаты
                </ImageText>
                <Image src={image6} />
              </ImageBlock>
            </ImagesBlock>
          </TutorialContainer>
        )
      }
      {(!isTutorial && !isMobileHeading && !isMobileTutorial) && (
          <>
            <ViewerCanvas 
              width={1920} 
              height={1080} 
              ref={canvasRef}
              style={mode === '3d' ? { visibility: 'hidden' } : {}}
            />

            {totalHouses > 1 && mode === '3d' &&
              <>
                <ArrowLeft onClick={() => setHouse(house === 1 ? totalHouses : (house - 1))} />
                <ArrowRight onClick={() => setHouse(house === totalHouses ? 1 : (house + 1))} />
              </>
            }

            <ModeWrapper>
              <ModeButton active={mode === '2d'} onClick={() => setMode('2d')}>2D</ModeButton>
              <ModeButton active={mode === '3d'} onClick={() => setMode('3d')}>3D</ModeButton>
            </ModeWrapper>
          </>
        )
      }
      
      <div style={(!(isTutorial || isMobileTutorial) && windowColor!=='' && mode === '3d') ? { visibility: 'visible' } : { visibility: 'hidden' }}>
        <Viewer3d 
          scale={windowScale} 
          canvasRef={canvasRef} 
          mode={mode} 
          house={house} 
          imageData={imageData} 
          preloader={preloader} 
        />
      </div>

      {(!isTutorial && !isMobileHeading && !isMobileTutorial && mode === '2d') && (windowColor) &&
        <Window color={windowColor} mode={mode}>
          <img src={windowsConfig[windowColor]} alt="" style={{ width: '100%' }} />
        </Window>
      }

      {!(isMobileTutorial || isMobileHeading) &&
        <Developer>
          Разработчик <a href="https://pahomov.pro">pahomov.pro</a>
        </Developer>
      }
    </ViewerContainer>
  );
};

class Viewer3d extends React.PureComponent {
  render() {
    const { scale, house, imageData, preloader, mode } = this.props;

    return preloader ? (
      <Viewer3dRoot>
        <LoaderContainer>
          <VendorLoader
            type='Rings'
            color='#C85F37'
            height={100}
            width={100}
          />
          <LoaderText>Loading</LoaderText>
        </LoaderContainer>
      </Viewer3dRoot>
    ) : (
      <Viewer3dRoot>
        <House1 scale={scale} image={house1} visible={house === 1 && mode === '3d'} />
      </Viewer3dRoot>
    );

    function House1(props) {
      return (
        <House scale={props.scale} visible={props.visible}>
          <CubesWrapper1>
            <Cube11Wrapper>
              <Cube11>
                <CanvasImg111 src={imageData} />
                <CanvasImg112 src={imageData} />
                <CanvasImg113 src={imageData} />
                <CanvasImg114 src={imageData} />
                <CanvasImg115 src={imageData} />
                <CanvasImg116 src={imageData} />
                <CanvasImg117 src={imageData} />
              </Cube11>
            </Cube11Wrapper>

            <Cube12Wrapper>
              <Cube12>
                <CanvasImg121 src={imageData} />
                <CanvasImg122 src={imageData} />
                <CanvasImg123 src={imageData} />
              </Cube12>
            </Cube12Wrapper>
          </CubesWrapper1>

          <House1Image>
            <img src={props.image} alt="house 1" />
          </House1Image>
        </House>
      );
    }
  }
}