import React from 'react';

export const WildBond = () => (
  <svg width="65" height="23" viewBox="0 0 65 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="32" y="7" width="17" height="7" rx="1" transform="rotate(-180 32 7)" fill="#C4C4C4" className='main' />
    <rect x="32" y="23" width="17" height="7" rx="1" transform="rotate(-180 32 23)" fill="#C4C4C4" className='main' />
    <rect x="27" y="15" width="17" height="7" rx="1" transform="rotate(-180 27 15)" fill="#959595" className='marked' />
    <rect x="50" y="7" width="17" height="7" rx="1" transform="rotate(-180 50 7)" fill="#959595" className='marked' />
    <rect x="50" y="23" width="17" height="7" rx="1" transform="rotate(-180 50 23)" fill="#C4C4C4" className='main' />
    <rect x="55" y="15" width="17" height="7" rx="1" transform="rotate(-180 55 15)" fill="#C4C4C4" className='main' />
    <rect x="14" y="7" width="9" height="7" rx="1" transform="rotate(-180 14 7)" fill="#959595" className='marked' />
    <rect x="9" y="15" width="9" height="7" rx="1" transform="rotate(-180 9 15)" fill="#C4C4C4" className='main' />
    <rect x="65" y="15" width="9" height="7" rx="1" transform="rotate(-180 65 15)" fill="#C4C4C4" className='main' />
    <rect x="14" y="23" width="9" height="7" rx="1" transform="rotate(-180 14 23)" fill="#959595" className='marked' />
    <rect x="37" y="15" width="9" height="7" rx="1" transform="rotate(-180 37 15)" fill="#959595" className='marked' />
    <rect x="60" y="7" width="9" height="7" rx="1" transform="rotate(-180 60 7)" fill="#C4C4C4" className='main' />
    <rect x="60" y="23" width="9" height="7" rx="1" transform="rotate(-180 60 23)" fill="#959595" className='marked' />
  </svg>
);
