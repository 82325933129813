import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const NavigationContainer = styled.div`
  flex-basis: 5.3125rem;
  max-width: 5.3125rem;

  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;

  height: 100%;
  background: #27282A;
  border-radius: 15px;

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;
`;

export const Logo = styled.img`
  margin-top: 1.5rem;
`;

export const NavigationButtons = styled.ul`
  padding: 0;

  flex-grow: 0;
  flex-shrink: 0;
`;

export const NavigationItem = styled.li`
  list-style-type: none;
  width: 100%;
`;

export const NavigationButton = styled.button`
  width: 100%;
  height: 4.1875rem;
  position: relative;
  padding: 0;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  ${ifProp({ fillIcon: false }, css`
    &:hover div svg * {
      stroke: #F48120;
    }

    &:hover p {
      display: none;
    }
  `)}

  ${ifProp({ fillIcon: true }, css`
    &:hover div svg * {
      fill: #F48120;
    }

    &:hover p {
      display: none;
    }
  `)}

  ${ifProp({ active: true }, css`
    
    &::before {
      top: 0.5rem;
      position: absolute;
      content: '';
      width: 90%;
      right: 0;
      height: 3.1875rem;
      background: #434748;
      z-index: -1;
      border-radius: 3.1875rem 0 0 3.1875rem;
    }

    ${ifProp({ fillIcon: false }, css`
      div svg * {
        stroke: #F48120;
      }

      p {
        display: none;
      }
    `)}

    ${ifProp({ fillIcon: true }, css`
      div svg * {
        fill: #F48120;
      }

      p {
        display: none;
      }
    `)}
  `)}

  @media screen and (max-width: 1000px) {
    &::before {
      top: 0;
      width: 70%;
      margin-right: 15%;
      height: 70%;
      border-radius: 0 0 3.1875rem 3.1875rem;
    }
  }
`;

export const NavigationButtonDecoration = styled.div`
  top: 0;
  display: none;
  position: absolute;
  content: '';
  width: 16px;
  right: 0;
  height: 4.1875rem;
  background: #434748;
  z-index: -1;

  ${({ active }) => active && css`
    display: block;
  `}

  &::before {
    top: -16px;
    width: 48px;
    height: 24px;
    z-index: -1;
    right: 0;
    position: absolute;
    border-radius: 24px;
    content: '';
    background: #27282A;
  }

  &::after {
    bottom: -16px;
    width: 48px;
    height: 24px;
    z-index: -1;
    right: 0;
    position: absolute;
    border-radius: 24px;
    content: '';
    background: #27282A;
  }

  ${({ isMobile }) => isMobile && css`
    width: 100%;
    height: 16px;
    margin-top: 0 !important;

    &::before {
      top: 0;
      width: 28px;
      height: 48px;
      right: -12px;
    }

    &::after {
      top: 0;
      width: 28px;
      height: 48px;
      left: -12px;
    }
  `}
`

export const ButtonLogo = styled.div`
  margin-top: 1.4875rem;
`;

export const ButtonText = styled.p`
  margin: 0;
  margin-top: .3125rem;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: .875rem;
  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
`;

export const DownloadBlock = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
`;

export const DownloadButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  width: 100%;
  flex-basis: 3.9375rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  :hover {
    * {
      fill: #c4c4c4;
    }
  }

  ${({ isActive }) => isActive && css`
    background-color: #262626;

    * {
      fill: #F48120;
      color: white;
    }
  `}
`;

export const LinkBlock = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: flex-end;
`;

export const LinkButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  color: white;
  text-decoration: none;

  width: 100%;
  margin-bottom: 15px;
  flex-basis: 3.9375rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  :hover {
    * {
      fill: #c4c4c4;
    }
  }

  ${({isActive}) => isActive && css`
    background-color: #262626;

    * {
      fill: #F48120;
      color: white;
    }
  `}
`;

export const LogoButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  color: #f38120;

  width: 100%;
  flex-basis: 3.9375rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  :hover {
    * {
      fill: #c4c4c4;
    }
  }

  ${({isActive}) => isActive && css`
    background-color: #262626;

    * {
      fill: #F48120;
      color: white;
    }
  `}
`;

export const DownloadLogoBlock = styled.div`
  margin-bottom: .5rem;
`;

export const DownloadButtonText = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: .875rem;
  text-align: center;

  color: #FFFFFF;

  opacity: 0.5;
`;
