import styled from 'styled-components';

export const HeaderContainer = styled.div`
  height: 103px;
  box-sizing: border-box;

  margin-left: 153px;
  margin-right: 41px;

  margin-bottom: 40px;

  display: flex;
`;

export const HeaderLogoBlock = styled.div`
  margin-top: 47px;
  margin-right: 117px;
`;

export const HeaderLogo = styled.img`
  width: 300px;
  margin-left: -101px;
`;

export const HeaderTextBlock = styled.div`
  margin-top: 43px;
  margin-bottom: 14px;
`;

export const HeaderTextTitle = styled.h3`
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 100%;

  margin-bottom: 4px;
`;

export const HeaderTextAddress = styled.p`
  padding: 0;
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;

  margin-bottom: 4px;
`;

export const HeaderTextContacts = styled.p`
  padding: 0;
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 100%;
`;
