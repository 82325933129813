import React from 'react';
import { MinSeamSize } from './icons/MinSeamSize';
import { StandartSeamSize } from './icons/StandartSeamSize';
import { MaxSeamSize } from './icons/MaxSeamSize';
import F75_cherniy from './images/F75_cherniy.jpg';
import F75_beliy from './images/F75_beliy.jpg';
import F75_korich from './images/F75_korich.jpg';
import FL75_bezh from './images/FL75_bezh.jpg';
import FL75_seriy from './images/FL75_seriy.jpg';

export const seamTypesOptions = [
  {
    icon: <MinSeamSize />,
    label: '8 ММ',
    value: 'min',
  },
  {
    icon: <StandartSeamSize />,
    label: '10 ММ',
    value: 'standart',
  },
  {
    icon: <MaxSeamSize />,
    label: '12 ММ',
    value: 'max',
  }
];

export const seamColorOptions = [
  {
    image: F75_cherniy,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/F75_cherniy.jpg`,
    colorCode: 'F75_cherniy',
    label: 'FL75 Черный'
  },
  {
    image: F75_beliy,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/F75_beliy.jpg`,
    colorCode: 'F75_beliy',
    label: 'FL75 Белый',
  },
  {
    image: F75_korich,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/F75_korich.jpg`,
    colorCode: 'F75_korich',
    label: 'FL75 Коричневый',
  },
  {
    image: FL75_bezh,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/FL75_bezh.jpg`,
    colorCode: 'FL75_bezh',
    label: 'FL75 Бежевый',
  },
  {
    image: FL75_seriy,
    imagePath: `${process.env.PUBLIC_URL}/images/seam/FL75_seriy.jpg`,
    colorCode: 'FL75_seriy',
    label: 'FL75 Серый',
  },
];