import React, { useState, useCallback } from 'react';
import {
  BondOption, BondOptionIconContainer, BondOptions,
  BondSelectorContainer, BondOptionFirstLabel, BondOptionText, BondOptionSecondLabel,
  BondSelectorHeader, BondSelectorHeaderText,
} from './styled';
import { bondOptions } from './options';

export const BondSelector = ({ onChangeBond, currentBond }) => {
  const handleClickOnOption = useCallback((bond) => () => {
    onChangeBond(bond);
  }, [onChangeBond]);

  const renderOptions = bondOptions.map(({ icon, firstLabel, secondLabel, value }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = value === currentBond;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <BondOption
        key={value}
        isActive={isActiveOption}
        onClick={handleClickOnOption(value)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <BondOptionIconContainer isActive={isActiveOption}>
          {icon}
        </BondOptionIconContainer>

        <BondOptionText>
          <BondOptionFirstLabel isActive={isActiveOption}>{firstLabel}</BondOptionFirstLabel>
          <BondOptionSecondLabel isActive={isActiveOption}>{secondLabel}</BondOptionSecondLabel>
        </BondOptionText>
      </BondOption>
    );
  });

  return (
    <BondSelectorContainer>
      <BondSelectorHeader>
        <BondSelectorHeaderText>
          Перевязка
        </BondSelectorHeaderText>
      </BondSelectorHeader>

      <BondOptions>
        {renderOptions}
      </BondOptions>
    </BondSelectorContainer>
  );
} 
