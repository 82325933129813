import styled from 'styled-components';

export const ModalText = styled.span`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 29px;
  margin-top: 1rem;
  width: 100%;
  text-transform: uppercase;
  color: white;
  opacity: 0.5;

  text-align: center;
`;

export const ModalButtons = styled.div`
  margin-top: 2rem;

  display: flex;
  justify-content: space-between;
`;

export const CloseModalIcon = styled.img`
  position: absolute;
  top: -19%;
  left: 99%;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  height: 48px;
  padding: 0 16px;
  margin: 0 8px;

  background: #F48120;
  border-radius: 15px;

  outline: none;
  border: 0;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1rem;

  color: #FFFFFF;
  cursor: pointer;
`;

export const ModalButtonDark = styled(ModalButton)`
  background: #27282A;
`;
