import React from 'react';

export const QuarterBond = () => (
  <svg width="63" height="23" viewBox="0 0 63 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="40" y="7" width="17" height="7" rx="1" transform="rotate(-180 40 7)" fill="#959595" className='marked' />
    <rect x="27" y="15" width="17" height="7" rx="1" transform="rotate(-180 27 15)" fill="#C4C4C4" className='main' />
    <rect x="22" y="23" width="17" height="7" rx="1" transform="rotate(-180 22 23)" fill="#C4C4C4" className='main' />
    <rect x="45" y="15" width="17" height="7" rx="1" transform="rotate(-180 45 15)" fill="#959595" className='marked' />
    <rect x="40" y="23" width="17" height="7" rx="1" transform="rotate(-180 40 23)" fill="#959595" className='marked' />
    <rect x="58" y="23" width="17" height="7" rx="1" transform="rotate(-180 58 23)" fill="#C4C4C4" className='main' />
    <rect x="58" y="7" width="17" height="7" rx="1" transform="rotate(-180 58 7)" fill="#C4C4C4" className='main' />
    <rect x="22" y="7" width="17" height="7" rx="1" transform="rotate(-180 22 7)" fill="#C4C4C4" className='main' />
    <rect x="9" y="15" width="9" height="7" rx="1" transform="rotate(-180 9 15)" fill="#959595" className='marked' />
    <rect x="63" y="15" width="17" height="7" rx="1" transform="rotate(-180 63 15)" fill="#C4C4C4" className='main' />
  </svg>
);