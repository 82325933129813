import styled from 'styled-components';

export const SidebarContainer = styled.aside`
  overflow: hidden;
  flex-basis: 19.625rem;
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;

  @media screen and (max-width: 1000px) {
    flex-basis: 70%;
    flex-grow: 1;

    & > div {
      justify-content: center;
    }
  }
`;


export const SidebarWrapper = styled.aside`
  position: relative;
  z-index: 1;
  display: flex;
  flex-basis: calc(19.625rem + 5.3125rem);
  
  border-radius: 15px;
  background: #434748;

  @media screen and (max-width: 1000px) {
    top: 0;
    border-radius: 0;
    flex-basis: calc(100% - 110px);
  }
`;