import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const ChooseBrickIconContainer = styled.div`
  width: 4rem;
  height: 4rem;

  border-radius: 50%;

  margin-bottom: 1rem;
  
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BrickSelectorContainer = styled.div``;

export const SelectorMainViewContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;

  box-sizing: border-box;
  overflow: hidden;
`;

export const AddButtonBlock = styled.div`
  height: 2rem;

  margin: 3px 0 13px 0;

  display: flex;
  cursor: pointer;

  ${ChooseBrickIconContainer} {
    height: 2rem;
    width: 2rem;
    
    margin-left: 3px;

    svg {
      height: 20px;
    }
  }
`;

export const AddButtonBlockText = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 10.4875rem;

  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
  text-transform: uppercase;
`;

export const SelectedSections = styled.div`
  margin-left: 1.4375rem;
  width: 15.875rem;
  flex-basis: 100%;
  display: flex;
  box-sizing: border-box;

  flex-direction: column;

  margin-top: .75rem;
  margin-bottom: .75rem;
  overflow: hidden;

  z-index: 1;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

export const SelectorsMainView = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (max-width: 1000px) {
    width: 100%;
    min-height: calc(100% - 110px);
  }
`;

export const TransparentBlock = styled.div`
  display: flex;
  flex-shrink: 0;
  min-height: 13px;
  flex-basis: 13px;
  width: 100%;
  bottom: 0px;

  background: transparent;
`;

export const SelectedSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #ECECEC;

  min-height: ${({ height }) => height}%;
  max-height: ${({ height }) => height}%;

  cursor: pointer;
  background: #434748;

  box-sizing: border-box;
`;

export const SelectedSectionContent = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  background: #27282A;
  border-radius: 15px;
  overflow: hidden;

  flex-basis: calc(100% - 13px);
  flex-grow: 1;
  flex-shrink: 1;

  @media screen and (max-width: 1000px) {
    width: 96%;
  }
`;

export const AfterSelectedSection = styled.div`
  height: 1px;
  width: 100%;
  background: #F48120;

  margin-bottom: .15625rem;
`;

export const ScaleBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 2.3125rem;
  margin-bottom: .75rem;
  margin-top: .75rem;
`;

export const ScaleMarkContainer = styled.div`
  height: ${({ scaleblockheight }) => scaleblockheight}%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-right: 4px;
`;

export const ScaleText = styled.p`
  margin: 0;
  opacity: 0.5;
  text-align: center;
  margin-left: 0.1rem;
  width: 80%;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;

  font-style: normal;
  font-weight: normal;
  font-size: .6875rem;
  line-height: .8125rem;

  color: #FFFFFF;

  ${ifProp({ isbold: true }, css`
    opacity: 1;
  `)}
`;

export const ScaleMark = styled.div`
  height: 2px;
  border-radius: 1px;
  width: 0.9125rem;
  margin-right: 0.2rem;

  background: #9B9D9E;

  ${ifProp({ isbold: true }, css`
    height: 3px;
    border-radius: 2px;
  `)}
`;

export const DragTrack = styled.div`
  flex-grow: 1;
  width: 100%;
  display: flex;

  top: calc(10% + 10px);

  ${({ isWithButton }) => isWithButton && css`top: calc(10% + 10px);`}

  height: calc(80% - 20px);
  width: calc(100% - 3.4rem);

  ${({ isWithButton }) => isWithButton && css`height: calc(80% - 20px);`}

  z-index: 3;
  position: absolute;
  left: 1.4625rem;

  justify-content: center;

  pointer-events: none;
`;

export const DragTrackInnerBlock = styled.div`
  width: 5px;
  height: 100%;
  border-radius: 4px;
  align-self: center;
`;

export const DragThumbContainer = styled.div`
  height: 1.6875rem;
  width: 100%;
  border-radius: 5px;

  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
`;

export const DragThumb = styled.div`
  width: 100%;
  height: 1.4375rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  pointer-events: auto;

  img {
    width: 1rem;
    height: 1rem;
    pointer-events: none;
  }
`;

export const DragArrow = styled.img`
  position: absolute;
  right: 0;
`;

export const ChooseBrickBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
`;

export const ChooseBrickText = styled.p`
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;

  color: #F4F4F4;

  opacity: 0.4;
`;

export const SectionTitle = styled.p`
  margin-left: 26px;

  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;

  color: #FFFFFF;

  opacity: 0.5;

  @media screen and (max-width: 1000px) {
    display: none; 
  }
`;

export const PercentOfBrick = styled.p`
  margin: 0;
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .6875rem;
  line-height: .8125rem;

  margin-right: .35rem;
  text-align: center;
`;

export const ChooseItemContainer = styled.div`
  margin-top: .75rem;
  margin-left: .9375rem;
  display: flex;

  height: calc(100% - .75rem);
  width: calc(100% - .625rem);
`;

export const ChooseItemOptions = styled.ul`
  padding: 0;

  width: 17.9375rem;

  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1000px) {
    width: calc(100% - .75rem); 
  }
`;

export const ChooseItemOptionButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #F48120;
  border-radius: 0.1875rem;

  position: absolute;
  cursor: pointer;
  margin-top: .2rem;
  margin-left: 2.3rem;
`;

export const ChooseItemOption = styled.li`
  position: relative;
  list-style-type: none;

  height: 5.0625rem;
  margin-bottom: 1rem;
  width: 90%;
  overflow: hidden;
  border-radius: 1.2rem;

  display: flex;
  align-items: center;
  cursor: pointer;

  box-sizing: border-box;
  box-shadow: 0 2px 5px 0px rgba(0,0,0,0.3);

  @media screen and (max-width: 1000px) {
    width: 100%;
  }

  ${ifProp({ isActive: true }, css`
    border: 3px solid #F48120;
  `)}
`;

export const ChooseItemOptionImage = styled.div`
  position: absolute;
  border-radius: 1rem;
  border-color: transparent;
  background: url(${({ brickImage }) => brickImage}) repeat-y;
  background-size: contain;

  width: 100%;
  height: 100%;

  margin: 0 .875rem 0 0;

  box-sizing: border-box;
`;

export const SelectedOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  flex-grow: 1;
`;

export const BraerPremiumImage = styled.img`
  position: absolute;
  margin-left: 4.8125rem;
`;

export const SelectedOptionImage = styled.div`
  border-color: transparent;

  position: absolute;
  width: 100%;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;

  box-sizing: border-box;
  
  background-image: url(${({ background }) => background});
  background-size: contain;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const SelectedOptionIconBlock = styled.div`
  width: 2.25rem;
  height: 2.25rem;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:first-of-type {
    margin-right: .6875rem;
    margin-left: 1rem;
  }
`;

export const ChooseItemOptionText = styled.p`
  font-family: 'Segoe UI', 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  max-width: 4.6875rem;
  line-height: 1rem;

  max-width: 60%;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;
  padding-right: 16px;
`;

export const SelectedOptionLabelWrapper = styled.div`
  position: absolute;
  word-break: break-word;
  z-index: 1;
  margin-left: auto;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  pointer-events: none;
  height: 100%;
  margin: 0;
  background: linear-gradient(270.07deg, rgba(39, 40, 42, 0.77) 38.54%, rgba(39, 40, 42, 0) 85.36%);
`;

export const SelectedOptionIcon = styled.img`
  width: 100%;
  height: 100%;
`;

export const SelectorFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Counter = styled.div`
  width: 35px;
  margin: 3px 3px 13px 0;
  color: white;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.1875rem;
`;

export const SelectedOptionLabel = styled(ChooseItemOptionText)`
`;
